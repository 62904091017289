// Custom

.rbt-aux {
  top: 4px !important;
}

.close {
  font-size: 2.2rem !important;
  font-weight: 400 !important;
}

#dropdown-popover > .arrow {
  display:none !important;
}

.icon-center {
  position: absolute;
  left: 35px;
  width: 12px !important;
  height: 12px !important;

}

.triangle-muted {
  color: #C0C2C5 !important;
}

.bg-muted {
  background-color: #C0C2C5 !important;
}

.text-black {
  color: black !important;
}

.p-m-0 {
  padding: 0 !important;
  margin: 0 !important;
}

.p-m-0 {
  padding: 0 !important;
  margin: 0 !important;
}

.multiselect {
  &__control {
    border: 1px solid #e2e5ec !important;
    background-color: white !important;
  }

  &__multi-value {
    background-color: #F3F6F9 !important;
    color: black !important;
  }

  &__menu {
    margin: 0rem auto !important;
  }

  &__option {
    background-color: white !important;
    color: black !important;

    &--is-focused {
      background-color: #F3F6F9 !important;
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid red !important;
  }
}

.header-no-padding tr th {
  padding-top: 0 !important;
}

.table-row-py > td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

tr.table-active-select {
  td, th {
    background-color: #ECF0F3;
  }
}

.pulsating-circle {
  position: absolute;
  left: -10%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #46e5dd;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #1BC5BD;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.settings-row {
  background: #e5eaee;
  opacity: .15;
}

@keyframes fadeToWhite {
  from {
    background-color: #ffedce;
  }
  to {
    background-color: #fafafa;
  }
}

.animate-row {
  animation: fadeToWhite 1.5s forwards;
}

.breadcrumb {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.breadcrumb.breadcrumb-transparent {
  background-color: transparent;
  border-radius: 0; }
.breadcrumb.breadcrumb-dot .breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:before {
  display: none; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:after {
  display: block;
  content: " ";
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: #D6D6E0;
  margin-left: 0.5rem; }
.breadcrumb.breadcrumb-dot .breadcrumb-item:last-child:after {
  display: none; }
.breadcrumb.breadcrumb-dot .breadcrumb-item.arrow:after {
  display: none
}

.badge-dot {
  display: inline-block;
  content: " ";
  height: 6px;
  width: 6px;
  margin-right: 3px;
  border-radius: 50%;
}

.dropdown-item {
  color: black !important;
}

.dropdown-item.disabled {
  color: #74788d !important;
}

.not-allowed, .not-allowed * {
  cursor: not-allowed;
  opacity: .8;
}

.not-allowed-cursor {
  cursor: not-allowed !important;
}

.input-group-solid {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #262626 !important;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.input-group-solid-dark {
  background-color: #ECF0F3FF;
}

// Custom react select

.select-warehouse > div {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #464E5F;
  box-shadow: none;
}

.select-warehouse.border-radius-left-0 > div {
  border-top-left-radius: 0;
  border-bottom-left-radius:0;
}

.select-warehouse > div:hover {
  border-color: #d3d6d9;
}

.select-warehouse--is-disabled {
  opacity: 0.65 !important;
}

.select-warehouse__control--is-focused, .select-warehouse__control--menu-is-open {
  border-color: #d3d6d9 !important;
}

// Menu z index
.select-warehouse__menu {
  z-index: 100 !important;
}

// separator
.select-warehouse__indicators-separator {
  background-color: hsl(0, 0%, 30%) !important;
}

// text
.select-warehouse__single-value {
  font-weight: 400 !important;
  color: hsl(0, 0%, 15%) !important;
}

// chevron
.select-warehouse__indicator {
  color: hsl(0, 0%, 30%) !important;
}

// placeholder
.select-warehouse__menu-notice {
  color: hsl(0, 0%, 45%) !important;
}

// Form control

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control:active, .form-control.active, .form-control:focus, .form-control.focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-control.form-control-solid {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: hsl(0, 0%, 15%) !important;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.form-control.form-control-solid::-moz-placeholder {
  color: #B5B5C3;
  opacity: 1;
}

.form-control.form-control-solid:-ms-input-placeholder {
  color: #B5B5C3;
}

.form-control.form-control-solid::-webkit-input-placeholder {
  color: #B5B5C3;
}

.form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus {
  background-color: #ECF0F3;
  border-color: #ECF0F3;
  color: #464E5F;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.form-control-noArrows::-webkit-outer-spin-button,
.form-control-noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control-noArrows[type=number] {
  -moz-appearance: textfield;
}


// Nav tabs

.nav .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  padding: 0.75rem 1.5rem;
  color: #80808F;
}

.nav .nav-link .nav-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 1rem;
  color: #80808F;
  font-weight: 400;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.nav .nav-link .nav-icon {
  line-height: 0;
  color: #80808F;
  width: 2rem;
}

.nav .nav-link .nav-icon:before {
  line-height: 0;
}

.nav .nav-link .nav-icon i {
  color: #B5B5C3;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  font-size: 1.25rem;
}

.nav .nav-link .nav-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}

.nav .nav-link .nav-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.nav .nav-link .label {
  margin-left: 0.25rem;
}

.nav .nav-link.disabled {
  opacity: 0.6;
}

.nav .show > .nav-link,
.nav .nav-link:hover:not(.disabled),
.nav .nav-link.active {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #6993FF;
}

.nav .show > .nav-link .nav-text,
.nav .nav-link:hover:not(.disabled) .nav-text,
.nav .nav-link.active .nav-text {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #6993FF;
}

.nav .show > .nav-link .nav-icon i,
.nav .nav-link:hover:not(.disabled) .nav-icon i,
.nav .nav-link.active .nav-icon i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #6993FF;
}

.nav .show > .nav-link .nav-icon .svg-icon svg g [fill],
.nav .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav .nav-link.active .nav-icon .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.nav .show > .nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.nav .show > .nav-link:after,
.nav .nav-link:hover:not(.disabled):after,
.nav .nav-link.active:after {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  color: #6993FF;
}

.nav.nav-bold .nav-item .nav-link {
  font-weight: 500;
}

.nav.nav-bold .nav-item .nav-link .nav-text {
  font-weight: 500;
}

.nav.nav-bolder .nav-item .nav-link {
  font-weight: 600;
}

.nav.nav-bolder .nav-item .nav-link .nav-text {
  font-weight: 600;
}

.nav.nav-boldest .nav-item .nav-link {
  font-weight: 700;
}

.nav.nav-boldest .nav-item .nav-link .nav-text {
  font-weight: 700;
}

.nav.nav-tabs .nav-item {
  margin-right: 0.25rem;
}

.nav.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav.nav-tabs .nav-link .nav-text {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.nav.nav-tabs.nav-tabs-line .nav-item {
  margin: 0 0 -1px 0;
}

.nav.nav-tabs.nav-tabs-line .nav-link {
  border: 0;
  border-bottom: 1px solid transparent;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
  padding: 0.85rem 0;
  margin: 0 1rem;
}

.nav-item:first-child .nav.nav-tabs.nav-tabs-line .nav-link {
  margin-left: 0;
}

.nav-item:last-child .nav.nav-tabs.nav-tabs-line .nav-link {
  margin-right: 0;
}

.nav.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
  margin-left: 0;
}

.nav.nav-tabs.nav-tabs-line .nav-item:last-child .nav-link {
  margin-right: 0;
}

.nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line .nav-link.active,
.nav.nav-tabs.nav-tabs-line .show > .nav-link {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #6993FF;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}


// Navi basics

.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

.navi .navi-item {
  padding: 0;
  display: block;
  list-style: none;
}

.navi .navi-item .navi-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
}

.navi .navi-item .navi-link {
  font-size: 1rem;
}

.navi .navi-item .navi-link .navi-text {
  font-size: 1rem;
}

.navi .navi-item .navi-link .navi-bullet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 0.75rem;
}

.navi .navi-item .navi-link .navi-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.navi .navi-item .navi-link .navi-label {
  line-height: 0;
  margin-left: 0.75rem;
}

.navi .navi-item:last-child {
  border-bottom: 0 !important;
}

.navi .navi-item:last-child .navi-link {
  border-bottom: 0 !important;
}

.navi.navi-link-rounded .navi-item .navi-link {
  border-radius: 0.42rem;
}

.navi.navi-light-icon .navi-item .navi-link {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #464E5F;
}

.navi.navi-light-icon .navi-item .navi-link.active {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #6993FF;
}

.navi.navi-active .navi-item .navi-link.active {
  background-color: #F3F6F9;
}

.navi .navi-item .navi-link .navi-icon {
  line-height: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.navi.navi-icon-center .navi-item .navi-link .navi-icon {
  text-align: center;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi .navi-item .navi-link .navi-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 2rem;
  flex: 0 0 2rem;
}

.navi .navi-item .navi-link .navi-icon i {
  font-size: 1.25rem;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon i {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.navi.navi-light-icon .navi-item .navi-link .navi-icon i {
  color: #D6D6E0;
}

.navi.navi-light-icon .navi-item .navi-link:hover {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #6993FF;
}

.navi.navi-hover .navi-item .navi-link:hover {
  background-color: #F3F6F9;
}

// Padding

.p-7 {
  padding: 1.75rem !important;
}

.pt-7,
.py-7 {
  padding-top: 1.75rem !important;
}

.pr-7,
.px-7 {
  padding-right: 1.75rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 1.75rem !important;
}

.pl-7,
.px-7 {
  padding-left: 1.75rem !important;
}

// Checkbox

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 1px;
}

.checkbox.checkbox-single {
  padding: 0;
  margin: 0;
  width: 18px;
  height: 18px;
}

.checkbox.checkbox-single > span {
  top: 0px;
}

.checkbox.checkbox-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.checkbox > span {
  border-radius: 0.42rem;
  position: absolute;
  top: 1px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  background-color: #ECF0F3;
  border: 1px solid transparent;
}

.checkbox > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox > span:after {
  content: '';
  border-color: transparent;
  border-style: solid;
  border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
  -webkit-transform: rotate(45deg) /*rtl:ignore*/;
  transform: rotate(45deg) /*rtl:ignore*/;
  margin-top: -2px;
}


.checkbox > span:after {
  width: 5px;
  height: 10px;
}

.checkbox > input:checked ~ span {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: none;
}

.checkbox > input:checked ~ span:after {
  display: block;
}

.checkbox > input:checked ~ span:after {
  border-color: #ffffff;
}


.checkbox > input:checked ~ span {
  background-color: #6993FF !important;
}


// Font sizes

.font-size-base {
  font-size: 1rem;
}

.font-size-sm {
  font-size: 0.925rem;
}

.font-size-xs {
  font-size: 0.8rem;
}

.font-size-lg {
  font-size: 1.08rem;
}

.font-size-h1 {
  font-size: 2rem !important;
}

.font-size-h2 {
  font-size: 1.75rem !important;
}

.font-size-h3 {
  font-size: 1.5rem !important;
}

.font-size-h4 {
  font-size: 1.35rem !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.font-size-h6 {
  font-size: 1.175rem !important;
}

// Navi Custom

.navi .navi-item .navi-link:hover .navi-text, small {
  color: black !important;
}

.navi .navi-item .navi-link.active .navi-text, small {
  color: black !important;
}

.navi .navi-item .navi-link .navi-text {
  font-weight: 500 !important;
  color: black !important
}

.navi .navi-item .navi-link {
  padding: 0.5rem !important;
}

.navi .navi-sub-item .navi-link .navi-text {
  font-weight: 300 !important;
  font-size: 0.9rem
}

.navi-sub-item {
  margin-left: 2rem !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

// LABEL

.label {
  padding: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: #ECF0F3;
  color: #464E5F;
  font-weight: 400;
}

.label.label-box {
  width: 20px;
  height: 20px;
}

.label.label-primary {
  color: #FFFFFF;
  background-color: #6993FF;
}

.label.label-outline-primary {
  background-color: transparent;
  color: #6993FF;
  border: 1px solid #6993FF;
}

.label.label-outline-primary.label-outline-2x {
  border: 2px solid #6993FF;
}

.label.label-light-primary {
  color: #6993FF;
  background-color: #E1E9FF;
}

.label.label-secondary {
  color: #464E5F;
  background-color: #E5EAEE;
}

.label.label-outline-secondary {
  background-color: transparent;
  color: #464E5F;
  border: 1px solid #E5EAEE;
}

.label.label-outline-secondary.label-outline-2x {
  border: 2px solid #E5EAEE;
}

.label.label-light-secondary {
  color: #E5EAEE;
  background-color: #ECF0F3;
}

.label.label-success {
  color: #ffffff;
  background-color: #1BC5BD;
}

.label.label-outline-success {
  background-color: transparent;
  color: #1BC5BD;
  border: 1px solid #1BC5BD;
}

.label.label-outline-success.label-outline-2x {
  border: 2px solid #1BC5BD;
}

.label.label-light-success {
  color: #1BC5BD;
  background-color: #C9F7F5;
}

.label.label-info {
  color: #ffffff;
  background-color: #8950FC;
}

.label.label-outline-info {
  background-color: transparent;
  color: #8950FC;
  border: 1px solid #8950FC;
}

.label.label-outline-info.label-outline-2x {
  border: 2px solid #8950FC;
}

.label.label-light-info {
  color: #8950FC;
  background-color: #EEE5FF;
}

.label.label-warning {
  color: #ffffff;
  background-color: #FFA800;
}

.label.label-outline-warning {
  background-color: transparent;
  color: #FFA800;
  border: 1px solid #FFA800;
}

.label.label-outline-warning.label-outline-2x {
  border: 2px solid #FFA800;
}

.label.label-light-warning {
  color: #FFA800;
  background-color: #FFF4DE;
}

.label.label-danger {
  color: #ffffff;
  background-color: #F64E60;
}

.label.label-outline-danger {
  background-color: transparent;
  color: #F64E60;
  border: 1px solid #F64E60;
}

.label.label-outline-danger.label-outline-2x {
  border: 2px solid #F64E60;
}

.label.label-light-danger {
  color: #F64E60;
  background-color: #FFE2E5;
}

.label.label-light {
  color: #80808F;
  background-color: #F3F6F9;
}

.label.label-outline-light {
  background-color: transparent;
  color: #464E5F;
  border: 1px solid #F3F6F9;
}

.label.label-outline-light.label-outline-2x {
  border: 2px solid #F3F6F9;
}

.label.label-light-light {
  color: #F3F6F9;
  background-color: #F3F6F9;
}

.label.label-dark {
  color: #ffffff;
  background-color: #212121;
}

.label.label-outline-dark {
  background-color: transparent;
  color: #212121;
  border: 1px solid #212121;
}

.label.label-outline-dark.label-outline-2x {
  border: 2px solid #212121;
}

.label.label-light-dark {
  color: #212121;
  background-color: #D6D6E0;
}

.label.label-white {
  color: #464E5F;
  background-color: #ffffff;
}

.label.label-outline-white {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.label.label-outline-white.label-outline-2x {
  border: 2px solid #ffffff;
}

.label.label-light-white {
  color: #ffffff;
  background-color: #ffffff;
}

.label.label-dark-75 {
  color: #ffffff;
  background-color: #464E5F;
}

.label.label-outline-dark-75 {
  background-color: transparent;
  color: #464E5F;
  border: 1px solid #464E5F;
}

.label.label-outline-dark-75.label-outline-2x {
  border: 2px solid #464E5F;
}

.label.label-light-dark-75 {
  color: #464E5F;
  background-color: #E5EAEE;
}

.label.label-inline {
  width: auto;
  padding: 0.15rem 0.75rem;
  border-radius: 0.42rem;
}

.label.label-inline.label-md {
  padding: 0.8rem 0.6rem;
}

.label.label-inline.label-lg {
  padding: 0.9rem 0.75rem;
}

.label.label-inline.label-xl {
  padding: 1rem 0.85rem;
}

.label.label-pill {
  border-radius: 2rem;
}

.label.label-rounded {
  border-radius: 0.42rem;
}

.label.label-square {
  border-radius: 0;
}

.label.label-dot {
  display: inline-block;
  font-size: 0 !important;
  vertical-align: middle;
  text-align: center;
}

.label.label-inline {
  width: auto;
}

.label.label-dot {
  line-height: 6px;
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
}

.label.label-sm {
  height: 16px;
  width: 16px;
  font-size: 0.75rem;
}

.label.label-sm.label-inline {
  width: auto;
}

.label.label-sm.label-dot {
  line-height: 4px;
  min-height: 4px;
  min-width: 4px;
  height: 4px;
  width: 4px;
}

.label.label-lg {
  height: 24px;
  width: 24px;
  font-size: 0.9rem;
}

.label.label-lg.label-inline {
  width: auto;
}

.label.label-lg.label-dot {
  line-height: 8px;
  min-height: 8px;
  min-width: 8px;
  height: 8px;
  width: 8px;
}

.label.label-xl {
  height: 28px;
  width: 28px;
  font-size: 1rem;
}

.label.label-xl.label-inline {
  width: auto;
}

.label.label-xl.label-dot {
  line-height: 10px;
  min-height: 10px;
  min-width: 10px;
  height: 10px;
  width: 10px;
}

// Card

.card-header.card-header-transparent {
  background-color: transparent;
  border-bottom: 0px;
}

.card-body.card-body-warehouse {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-footer.card-footer-warehouse {
background-color: #ffffff;
border-top: 1px solid #ECF0F3;
}

.card.card-custom > .card-header .card-toolbar-warehouse {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
justify-content: end;
margin: 0.5rem 0;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
}

// Badge

.badge {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 85%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.42rem;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #6993FF;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #366eff;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(105, 147, 255, 0.5);
}

.badge-secondary {
  color: #212121;
  background-color: #E5EAEE;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212121;
  background-color: #c6d1da;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(229, 234, 238, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(229, 234, 238, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #1BC5BD;
}

a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #159892;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(27, 197, 189, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #8950FC;
}

a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #671efb;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 80, 252, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(137, 80, 252, 0.5);
}

.badge-warning {
  color: #212121;
  background-color: #FFA800;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212121;
  background-color: #cc8600;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 168, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #F64E60;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #f41d34;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(246, 78, 96, 0.5);
}

.badge-light {
  color: #212121;
  background-color: #F3F6F9;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212121;
  background-color: #d1dde8;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(243, 246, 249, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #212121;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #080808;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(33, 33, 33, 0.5);
}

.badge-white {
  color: #212121;
  background-color: #ffffff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #212121;
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-dark-75 {
  color: #ffffff;
  background-color: #464E5F;
}

a.badge-dark-75:hover, a.badge-dark-75:focus {
  color: #ffffff;
  background-color: #303642;
}

a.badge-dark-75:focus, a.badge-dark-75.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 78, 95, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 78, 95, 0.5);
}

// Timeline

.timeline.timeline-4 {
  position: relative;
  width: 100%;
}

.timeline.timeline-4:after {
  content: '';
  position: absolute;
  width: 5px;
  top: 0;
  margin-top: 0.1rem;
  bottom: 0;
  left: 50%;
  margin-left: -2.5px;
  background-color: #ECF0F3;
  border-radius: 0.42rem;
}

.timeline.timeline-4.timeline-center {
  margin: 0 auto;
}

.timeline.timeline-4 .timeline-bar {
  border-radius: 0.42rem;
  width: 20px;
  height: 5px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  background-color: #ECF0F3;
}

.timeline.timeline-4 .timeline-items {
  position: relative;
}

.timeline.timeline-4 .timeline-items .timeline-item {
  position: relative;
  margin-left: 0;
  width: 50%;
  min-height: 3rem;
}

.timeline.timeline-4 .timeline-items .timeline-item:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 3rem;
  left: 100%;
  margin-left: -3rem;
  border-left: solid 10px #F3F6F9;
  border-bottom: solid 17px transparent;
  border-right: solid 17px transparent;
  border-top: solid 17px transparent;
}

.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 3.5rem;
}

.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge > div {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.timeline.timeline-4 .timeline-items .timeline-item .timeline-label {
  display: block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0.5rem;
}

.timeline.timeline-4 .timeline-items .timeline-item .timeline-content {
  position: relative;
  border-radius: 0.42rem;
  padding: 1rem 1.5rem;
  background-color: #F3F6F9;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-left {
  left: 0;
  padding-right: 2.85rem;
  position: relative;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-left .timeline-badge {
  right: -10px;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-left .timeline-label {
  text-align: right;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-right {
  left: 50%;
  padding-left: 2.86rem;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-right .timeline-badge {
  left: -10px;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-right .timeline-label {
  text-align: left;
}

.timeline.timeline-4 .timeline-items .timeline-item.timeline-item-right:after {
  right: 100%;
  left: auto;
  margin-right: -3rem;
  border-right: solid 10px #F3F6F9;
  border-left: solid 17px transparent;
}

.timeline.timeline-4 .timeline-items .timeline-item:first-child {
  top: 2rem;
}

.timeline.timeline-4 .timeline-items .timeline-item:last-child {
  bottom: 2rem;
}

.timeline.timeline-4.timeline-justified .timeline-bar {
  left: 5px;
}

.timeline.timeline-4.timeline-justified:after {
  left: 5px;
}

.timeline.timeline-4.timeline-justified .timeline-items {
  padding: 1rem 0;
}

.timeline.timeline-4.timeline-justified .timeline-items .timeline-item {
  width: 100%;
  left: 0;
  padding-left: 2.85rem;
  position: relative;
  top: 0;
  bottom: 0;
  margin-bottom: 1.5rem;
}

.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:after {
  right: 100%;
  left: auto;
  margin-left: auto;
  margin-right: -3rem;
  border-right: solid 10px #F3F6F9;
  border-left: solid 17px transparent;
}

.timeline.timeline-4.timeline-justified .timeline-items .timeline-item .timeline-badge {
  left: -5px;
}

.timeline.timeline-4.timeline-justified .timeline-items .timeline-item .timeline-label {
  text-align: left;
}

.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .timeline.timeline-4 .timeline-bar {
    left: 5px !important;
  }

  .timeline.timeline-4:after {
    left: 5px !important;
  }

  .timeline.timeline-4 .timeline-items {
    padding: 1rem 0;
  }

  .timeline.timeline-4 .timeline-items .timeline-item {
    width: 100% !important;
    left: 0 !important;
    padding-right: 0 !important;
    padding-left: 2.85rem !important;
    position: relative;
    top: 0 !important;
    bottom: 0 !important;
    margin-bottom: 1.5rem;
  }

  .timeline.timeline-4 .timeline-items .timeline-item:after {
    right: 100%;
    left: auto !important;
    margin-left: auto;
    margin-right: -3rem;
    border-right: solid 10px #F3F6F9;
    border-left: solid 17px transparent;
  }

  .timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
    left: -5px !important;
  }

  .timeline.timeline-4 .timeline-items .timeline-item .timeline-label {
    text-align: left !important;
  }

  .timeline.timeline-4 .timeline-items .timeline-item:last-child {
    margin-bottom: 0 !important;
  }
}

.timeline.timeline-5 {
  position: relative;
}

.timeline.timeline-5:before {
  content: '';
  position: absolute;
  left: 90px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #ECF0F3;
}

.timeline.timeline-5 .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}

.timeline.timeline-5 .timeline-item:last-child {
  margin-bottom: 0;
}

.timeline.timeline-5 .timeline-item .timeline-label {
  width: 70px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  color: #464E5F;
}

.timeline.timeline-5 .timeline-item .timeline-badge {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-left: 12px;
}

.timeline.timeline-5 .timeline-item .timeline-badge span {
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: #ECF0F3;
}

.timeline.timeline-5 .timeline-item .timeline-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-left: 20px;
}

// Buttons

.btn {
  outline: none !important;
  vertical-align: middle;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:hover:not(.btn-text), .btn:focus:not(.btn-text), .btn.focus {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:hover:not(.btn-text) i, .btn:focus:not(.btn-text) i, .btn.focus i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn.disabled, .btn:disabled {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn.disabled i, .btn:disabled i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:not(:disabled):not(.disabled):active:not(.btn-text), .btn:not(:disabled):not(.disabled).active,
.show > .btn.dropdown-toggle {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn:not(:disabled):not(.disabled).active i,
.show > .btn.dropdown-toggle i {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:focus:not(.btn-shadow):not(.btn-shadow-hover) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-square {
  border-radius: 0;
}

.btn.btn-pill {
  border-radius: 2rem;
}

.btn.btn-text {
  cursor: text;
}

.btn.btn-borderless {
  border-color: transparent;
}

.btn i {
  font-size: 1.3rem;
  padding-right: 0.35rem;
  vertical-align: middle;
}

.btn i:after, .btn i:before {
  line-height: 0;
}

.btn .svg-icon {
  margin-right: 0.5rem;
}

.btn .svg-icon svg {
  height: 18px;
  width: 18px;
}

.btn.btn-xs i {
  font-size: 1rem;
  padding-right: 0.3rem;
}

.btn.btn-xs .svg-icon {
  margin-right: 0.3rem;
}

.btn.btn-xs .svg-icon svg {
  height: 16px;
  width: 16px;
}

.btn.btn-sm i, .btn-group-sm > .btn i {
  font-size: 1.3rem;
  padding-right: 0.35rem;
}

.btn.btn-sm .svg-icon, .btn-group-sm > .btn .svg-icon {
  margin-right: 0.35rem;
}

.btn.btn-sm .svg-icon svg, .btn-group-sm > .btn .svg-icon svg {
  height: 16px;
  width: 16px;
}

.btn.btn-lg i, .btn-group-lg > .btn i {
  font-size: 1.5rem;
  padding-right: 0.75rem;
}

.btn.btn-lg .svg-icon, .btn-group-lg > .btn .svg-icon {
  margin-right: 0.75rem;
}

.btn.btn-lg .svg-icon svg, .btn-group-lg > .btn .svg-icon svg {
  height: 20px;
  width: 20px;
}

.btn.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.3rem + 2px);
  width: calc(1.5em + 1.3rem + 2px);
}

.btn.btn-icon.btn-xs {
  height: 24px;
  width: 24px;
}

.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: calc(1.35em + 1.1rem + 2px);
}

.btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon {
  height: calc(1.5em + 1.65rem + 2px);
  width: calc(1.5em + 1.65rem + 2px);
}

.btn.btn-icon.btn-circle {
  border-radius: 50%;
}

.btn.btn-icon i {
  padding: 0;
  margin: 0;
}

.btn.btn-icon [class^="socicon-"]:before,
.btn.btn-icon [class*=" socicon-"]:before {
  line-height: 1;
}

.btn.btn-icon .svg-icon {
  margin: 0;
  padding: 0;
}

.btn.btn-fh {
  padding-top: 0;
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn.btn-fh.btn {
  height: calc(1.5em + 1.3rem + 2px);
}

.btn.btn-fh.btn-sm, .btn-group-sm > .btn.btn-fh {
  height: calc(1.35em + 1.1rem + 2px);
}

.btn.btn-fh.btn-lg, .btn-group-lg > .btn.btn-fh {
  height: calc(1.5em + 1.65rem + 2px);
}

.btn.btn-clean {
  color: #B5B5C3;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-clean i {
  color: #B5B5C3;
}

.btn.btn-clean .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}

.btn.btn-clean .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-clean.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-clean:hover:not(.btn-text), .btn.btn-clean:focus:not(.btn-text), .btn.btn-clean.focus:not(.btn-text) {
  color: #6993FF;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-clean:hover:not(.btn-text) i, .btn.btn-clean:focus:not(.btn-text) i, .btn.btn-clean.focus:not(.btn-text) i {
  color: #6993FF;
}

.btn.btn-clean:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-clean:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-clean:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-clean.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-clean:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-clean:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-clean.focus:not(.btn-text).dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-clean.disabled, .btn.btn-clean:disabled {
  color: #B5B5C3;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-clean.disabled i, .btn.btn-clean:disabled i {
  color: #B5B5C3;
}

.btn.btn-clean.disabled .svg-icon svg g [fill], .btn.btn-clean:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}

.btn.btn-clean.disabled .svg-icon svg:hover g [fill], .btn.btn-clean:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-clean.disabled.dropdown-toggle:after, .btn.btn-clean:disabled.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-clean:not(:disabled):not(.disabled).active,
.show > .btn.btn-clean.dropdown-toggle,
.show .btn.btn-clean.btn-dropdown {
  color: #6993FF;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-clean:not(:disabled):not(.disabled).active i,
.show > .btn.btn-clean.dropdown-toggle i,
.show .btn.btn-clean.btn-dropdown i {
  color: #6993FF;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-clean:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-clean.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-clean.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-clean:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-clean.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-clean.btn-dropdown.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-default {
  color: #B5B5C3;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-default i {
  color: #B5B5C3;
}

.btn.btn-default .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}

.btn.btn-default .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-default.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-default:hover:not(.btn-text), .btn.btn-default:focus:not(.btn-text), .btn.btn-default.focus:not(.btn-text) {
  color: #6993FF;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-default:hover:not(.btn-text) i, .btn.btn-default:focus:not(.btn-text) i, .btn.btn-default.focus:not(.btn-text) i {
  color: #6993FF;
}

.btn.btn-default:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-default:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-default.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-default:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-default:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-default.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-default:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-default:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-default.focus:not(.btn-text).dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-default.disabled, .btn.btn-default:disabled {
  color: #B5B5C3;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-default.disabled i, .btn.btn-default:disabled i {
  color: #B5B5C3;
}

.btn.btn-default.disabled .svg-icon svg g [fill], .btn.btn-default:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #B5B5C3;
}

.btn.btn-default.disabled .svg-icon svg:hover g [fill], .btn.btn-default:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-default.disabled.dropdown-toggle:after, .btn.btn-default:disabled.dropdown-toggle:after {
  color: #B5B5C3;
}

.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-default:not(:disabled):not(.disabled).active,
.show > .btn.btn-default.dropdown-toggle,
.show .btn.btn-default.btn-dropdown {
  color: #6993FF;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-default:not(:disabled):not(.disabled).active i,
.show > .btn.btn-default.dropdown-toggle i,
.show .btn.btn-default.btn-dropdown i {
  color: #6993FF;
}

.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-default:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-default.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-default.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-default:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-default.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-default.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-default:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-default:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-default.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-default.btn-dropdown.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-primary {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-primary i {
  color: #FFFFFF;
}

.btn.btn-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-primary.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-primary:hover:not(.btn-text), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #4A7DFF;
  border-color: #4A7DFF;
}

.btn.btn-primary:hover:not(.btn-text) i, .btn.btn-primary:focus:not(.btn-text) i, .btn.btn-primary.focus:not(.btn-text) i {
  color: #FFFFFF;
}

.btn.btn-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-primary.disabled i, .btn.btn-primary:disabled i {
  color: #FFFFFF;
}

.btn.btn-primary.disabled .svg-icon svg g [fill], .btn.btn-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-primary.disabled.dropdown-toggle:after, .btn.btn-primary:disabled.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.dropdown-toggle,
.show .btn.btn-primary.btn-dropdown {
  color: #FFFFFF;
  background-color: #4A7DFF;
  border-color: #4A7DFF;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-primary.dropdown-toggle i,
.show .btn.btn-primary.btn-dropdown i {
  color: #FFFFFF;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-primary.btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-outline-primary {
  color: #6993FF;
  background-color: transparent;
  border-color: #6993FF;
}

.btn.btn-outline-primary i {
  color: #6993FF;
}

.btn.btn-outline-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-outline-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-outline-primary:hover:not(.btn-text), .btn.btn-outline-primary:focus:not(.btn-text), .btn.btn-outline-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-outline-primary:hover:not(.btn-text) i, .btn.btn-outline-primary:focus:not(.btn-text) i, .btn.btn-outline-primary.focus:not(.btn-text) i {
  color: #FFFFFF;
}

.btn.btn-outline-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-outline-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
  color: #6993FF;
  background-color: transparent;
  border-color: #6993FF;
}

.btn.btn-outline-primary.disabled i, .btn.btn-outline-primary:disabled i {
  color: #6993FF;
}

.btn.btn-outline-primary.disabled .svg-icon svg g [fill], .btn.btn-outline-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-outline-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-primary.disabled.dropdown-toggle:after, .btn.btn-outline-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-primary.dropdown-toggle,
.show .btn.btn-outline-primary.btn-dropdown {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-primary.dropdown-toggle i,
.show .btn.btn-outline-primary.btn-dropdown i {
  color: #FFFFFF;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-primary.btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-primary {
  color: #6993FF;
  background-color: #E1E9FF;
  border-color: transparent;
}

.btn.btn-light-primary i {
  color: #6993FF;
}

.btn.btn-light-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-light-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-light-primary:hover:not(.btn-text), .btn.btn-light-primary:focus:not(.btn-text), .btn.btn-light-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: transparent;
}

.btn.btn-light-primary:hover:not(.btn-text) i, .btn.btn-light-primary:focus:not(.btn-text) i, .btn.btn-light-primary.focus:not(.btn-text) i {
  color: #FFFFFF;
}

.btn.btn-light-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-light-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-light-primary.disabled, .btn.btn-light-primary:disabled {
  color: #6993FF;
  background-color: #E1E9FF;
  border-color: transparent;
}

.btn.btn-light-primary.disabled i, .btn.btn-light-primary:disabled i {
  color: #6993FF;
}

.btn.btn-light-primary.disabled .svg-icon svg g [fill], .btn.btn-light-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-light-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-light-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-primary.disabled.dropdown-toggle:after, .btn.btn-light-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-primary.dropdown-toggle,
.show .btn.btn-light-primary.btn-dropdown {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: transparent;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-primary.dropdown-toggle i,
.show .btn.btn-light-primary.btn-dropdown i {
  color: #FFFFFF;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-primary.btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-transparent-primary {
  color: #6993FF;
  background-color: rgba(105, 147, 255, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-primary i {
  color: #6993FF;
}

.btn.btn-transparent-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-transparent-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-transparent-primary:hover:not(.btn-text), .btn.btn-transparent-primary:focus:not(.btn-text), .btn.btn-transparent-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: transparent;
}

.btn.btn-transparent-primary:hover:not(.btn-text) i, .btn.btn-transparent-primary:focus:not(.btn-text) i, .btn.btn-transparent-primary.focus:not(.btn-text) i {
  color: #FFFFFF;
}

.btn.btn-transparent-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-transparent-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-transparent-primary.disabled, .btn.btn-transparent-primary:disabled {
  color: #6993FF;
  background-color: rgba(105, 147, 255, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-primary.disabled i, .btn.btn-transparent-primary:disabled i {
  color: #6993FF;
}

.btn.btn-transparent-primary.disabled .svg-icon svg g [fill], .btn.btn-transparent-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-transparent-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-primary.disabled.dropdown-toggle:after, .btn.btn-transparent-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-primary.dropdown-toggle,
.show .btn.btn-transparent-primary.btn-dropdown {
  color: #FFFFFF;
  background-color: #6993FF;
  border-color: transparent;
}

.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-primary.dropdown-toggle i,
.show .btn.btn-transparent-primary.btn-dropdown i {
  color: #FFFFFF;
}

.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF;
}

.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-primary.btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF;
}

.btn.btn-hover-transparent-primary {
  color: #6993FF;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-primary i {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-transparent-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary:hover:not(.btn-text), .btn.btn-hover-transparent-primary:focus:not(.btn-text), .btn.btn-hover-transparent-primary.focus:not(.btn-text) {
  color: #6993FF;
  background-color: rgba(105, 147, 255, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-primary:hover:not(.btn-text) i, .btn.btn-hover-transparent-primary:focus:not(.btn-text) i, .btn.btn-hover-transparent-primary.focus:not(.btn-text) i {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-transparent-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary.disabled, .btn.btn-hover-transparent-primary:disabled {
  color: #6993FF;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-primary.disabled i, .btn.btn-hover-transparent-primary:disabled i {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-transparent-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-primary.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle,
.show .btn.btn-hover-transparent-primary.btn-dropdown {
  color: #6993FF;
  background-color: rgba(105, 147, 255, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle i,
.show .btn.btn-hover-transparent-primary.btn-dropdown i {
  color: #6993FF;
}

.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-primary.btn-dropdown.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-light-primary:hover:not(.btn-text), .btn.btn-hover-light-primary:focus:not(.btn-text), .btn.btn-hover-light-primary.focus:not(.btn-text) {
  color: #6993FF;
  background-color: #E1E9FF;
  border-color: transparent;
}

.btn.btn-hover-light-primary:hover:not(.btn-text) i, .btn.btn-hover-light-primary:focus:not(.btn-text) i, .btn.btn-hover-light-primary.focus:not(.btn-text) i {
  color: #6993FF;
}

.btn.btn-hover-light-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-light-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-primary.dropdown-toggle,
.show .btn.btn-hover-light-primary.btn-dropdown {
  color: #6993FF;
  background-color: #E1E9FF;
  border-color: transparent;
}

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-primary.dropdown-toggle i,
.show .btn.btn-hover-light-primary.btn-dropdown i {
  color: #6993FF;
}

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-primary.btn-dropdown.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-hover-light-primary.btn-clean {
  border: 0 !important;
}

.btn.btn-link-primary {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #6993FF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-primary i {
  color: #6993FF;
}

.btn.btn-link-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-link-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-link-primary:hover:not(.btn-text), .btn.btn-link-primary:focus:not(.btn-text), .btn.btn-link-primary.focus:not(.btn-text) {
  color: #4A7DFF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-primary:hover:not(.btn-text) i, .btn.btn-link-primary:focus:not(.btn-text) i, .btn.btn-link-primary.focus:not(.btn-text) i {
  color: #4A7DFF;
}

.btn.btn-link-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #4A7DFF;
}

.btn.btn-link-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #4A7DFF;
}

.btn.btn-link-primary.disabled, .btn.btn-link-primary:disabled {
  color: #6993FF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-primary.disabled i, .btn.btn-link-primary:disabled i {
  color: #6993FF;
}

.btn.btn-link-primary.disabled .svg-icon svg g [fill], .btn.btn-link-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-link-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-link-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-primary.disabled.dropdown-toggle:after, .btn.btn-link-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-primary.dropdown-toggle,
.show .btn.btn-link-primary.btn-dropdown {
  color: #4A7DFF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-primary.dropdown-toggle i,
.show .btn.btn-link-primary.btn-dropdown i {
  color: #4A7DFF;
}

.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #4A7DFF;
}

.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-primary.btn-dropdown.dropdown-toggle:after {
  color: #4A7DFF;
}

.btn.btn-hover-primary:hover:not(.btn-text), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary.focus:not(.btn-text) {
  color: #FFFFFF !important;
  background-color: #6993FF !important;
  border-color: #6993FF !important;
}

.btn.btn-hover-primary:hover:not(.btn-text) i, .btn.btn-hover-primary:focus:not(.btn-text) i, .btn.btn-hover-primary.focus:not(.btn-text) i {
  color: #FFFFFF !important;
}

.btn.btn-hover-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF !important;
}

.btn.btn-hover-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFFFFF !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-primary.dropdown-toggle,
.show .btn.btn-hover-primary.btn-dropdown {
  color: #FFFFFF !important;
  background-color: #6993FF !important;
  border-color: #6993FF !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-primary.dropdown-toggle i,
.show .btn.btn-hover-primary.btn-dropdown i {
  color: #FFFFFF !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFFFFF !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-primary.btn-dropdown.dropdown-toggle:after {
  color: #FFFFFF !important;
}

.btn.btn-hover-primary.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-primary:hover:not(.btn-text), .btn.btn-hover-text-primary:focus:not(.btn-text), .btn.btn-hover-text-primary.focus:not(.btn-text) {
  color: #6993FF !important;
}

.btn.btn-hover-text-primary:hover:not(.btn-text) i, .btn.btn-hover-text-primary:focus:not(.btn-text) i, .btn.btn-hover-text-primary.focus:not(.btn-text) i {
  color: #6993FF !important;
}

.btn.btn-hover-text-primary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-primary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF !important;
}

.btn.btn-hover-text-primary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-primary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-primary.focus:not(.btn-text).dropdown-toggle:after {
  color: #6993FF !important;
}

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-primary.dropdown-toggle,
.show .btn.btn-hover-text-primary.btn-dropdown {
  color: #6993FF !important;
}

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-primary.dropdown-toggle i,
.show .btn.btn-hover-text-primary.btn-dropdown i {
  color: #6993FF !important;
}

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-primary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-primary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF !important;
}

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-primary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-primary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-primary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-primary.btn-dropdown.dropdown-toggle:after {
  color: #6993FF !important;
}

.btn.btn-hover-bg-primary:hover:not(.btn-text), .btn.btn-hover-bg-primary:focus:not(.btn-text), .btn.btn-hover-bg-primary.focus:not(.btn-text) {
  background-color: #6993FF !important;
  border-color: #6993FF !important;
}

.btn.btn-hover-bg-primary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-primary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-primary.dropdown-toggle,
.show .btn.btn-hover-bg-primary.btn-dropdown {
  background-color: #6993FF !important;
  border-color: #6993FF !important;
}

.btn.btn-text-primary {
  color: #6993FF;
}

.btn.btn-text-primary i {
  color: #6993FF;
}

.btn.btn-text-primary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-text-primary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-primary.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-text-primary.disabled, .btn.btn-text-primary:disabled {
  color: #6993FF;
}

.btn.btn-text-primary.disabled i, .btn.btn-text-primary:disabled i {
  color: #6993FF;
}

.btn.btn-text-primary.disabled .svg-icon svg g [fill], .btn.btn-text-primary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #6993FF;
}

.btn.btn-text-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-text-primary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-primary.disabled.dropdown-toggle:after, .btn.btn-text-primary:disabled.dropdown-toggle:after {
  color: #6993FF;
}

.btn.btn-bg-primary {
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-bg-primary.disabled, .btn.btn-bg-primary:disabled {
  background-color: #6993FF;
  border-color: #6993FF;
}

.btn.btn-primary.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.25) !important;
}

.btn.btn-primary.btn-shadow:hover:not(.btn-text), .btn.btn-primary.btn-shadow:focus:not(.btn-text), .btn.btn-primary.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow-hover:hover:not(.btn-text), .btn.btn-primary.btn-shadow-hover:focus:not(.btn-text), .btn.btn-primary.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
}

.btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-primary.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-primary.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(105, 147, 255, 0.3) !important;
}

.btn.btn-secondary {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-secondary i {
  color: #464E5F;
}

.btn.btn-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-secondary.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-secondary:hover:not(.btn-text), .btn.btn-secondary:focus:not(.btn-text), .btn.btn-secondary.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #D6D6E0;
  border-color: #D6D6E0;
}

.btn.btn-secondary:hover:not(.btn-text) i, .btn.btn-secondary:focus:not(.btn-text) i, .btn.btn-secondary.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-secondary.disabled i, .btn.btn-secondary:disabled i {
  color: #464E5F;
}

.btn.btn-secondary.disabled .svg-icon svg g [fill], .btn.btn-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-secondary.disabled.dropdown-toggle:after, .btn.btn-secondary:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.dropdown-toggle,
.show .btn.btn-secondary.btn-dropdown {
  color: #464E5F;
  background-color: #D6D6E0;
  border-color: #D6D6E0;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-secondary.dropdown-toggle i,
.show .btn.btn-secondary.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-secondary.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-secondary {
  color: #464E5F;
  background-color: transparent;
  border-color: #E5EAEE;
}

.btn.btn-outline-secondary i {
  color: #464E5F;
}

.btn.btn-outline-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-secondary.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-secondary:hover:not(.btn-text), .btn.btn-outline-secondary:focus:not(.btn-text), .btn.btn-outline-secondary.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-outline-secondary:hover:not(.btn-text) i, .btn.btn-outline-secondary:focus:not(.btn-text) i, .btn.btn-outline-secondary.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-outline-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
  color: #464E5F;
  background-color: transparent;
  border-color: #E5EAEE;
}

.btn.btn-outline-secondary.disabled i, .btn.btn-outline-secondary:disabled i {
  color: #464E5F;
}

.btn.btn-outline-secondary.disabled .svg-icon svg g [fill], .btn.btn-outline-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-secondary.disabled.dropdown-toggle:after, .btn.btn-outline-secondary:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-secondary.dropdown-toggle,
.show .btn.btn-outline-secondary.btn-dropdown {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-secondary.dropdown-toggle i,
.show .btn.btn-outline-secondary.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-secondary.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-secondary {
  color: #E5EAEE;
  background-color: #ECF0F3;
  border-color: transparent;
}

.btn.btn-light-secondary i {
  color: #E5EAEE;
}

.btn.btn-light-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-light-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-secondary.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-light-secondary:hover:not(.btn-text), .btn.btn-light-secondary:focus:not(.btn-text), .btn.btn-light-secondary.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-light-secondary:hover:not(.btn-text) i, .btn.btn-light-secondary:focus:not(.btn-text) i, .btn.btn-light-secondary.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-light-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-secondary.disabled, .btn.btn-light-secondary:disabled {
  color: #E5EAEE;
  background-color: #ECF0F3;
  border-color: transparent;
}

.btn.btn-light-secondary.disabled i, .btn.btn-light-secondary:disabled i {
  color: #E5EAEE;
}

.btn.btn-light-secondary.disabled .svg-icon svg g [fill], .btn.btn-light-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-light-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-light-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-secondary.disabled.dropdown-toggle:after, .btn.btn-light-secondary:disabled.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-secondary.dropdown-toggle,
.show .btn.btn-light-secondary.btn-dropdown {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-secondary.dropdown-toggle i,
.show .btn.btn-light-secondary.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-secondary.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-secondary {
  color: #E5EAEE;
  background-color: rgba(229, 234, 238, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-secondary i {
  color: #E5EAEE;
}

.btn.btn-transparent-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-transparent-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-secondary.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-transparent-secondary:hover:not(.btn-text), .btn.btn-transparent-secondary:focus:not(.btn-text), .btn.btn-transparent-secondary.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-transparent-secondary:hover:not(.btn-text) i, .btn.btn-transparent-secondary:focus:not(.btn-text) i, .btn.btn-transparent-secondary.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-transparent-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-secondary.disabled, .btn.btn-transparent-secondary:disabled {
  color: #E5EAEE;
  background-color: rgba(229, 234, 238, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-secondary.disabled i, .btn.btn-transparent-secondary:disabled i {
  color: #E5EAEE;
}

.btn.btn-transparent-secondary.disabled .svg-icon svg g [fill], .btn.btn-transparent-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-transparent-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-secondary.disabled.dropdown-toggle:after, .btn.btn-transparent-secondary:disabled.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-secondary.dropdown-toggle,
.show .btn.btn-transparent-secondary.btn-dropdown {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-secondary.dropdown-toggle i,
.show .btn.btn-transparent-secondary.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-secondary.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-transparent-secondary {
  color: #E5EAEE;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-secondary i {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-transparent-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-secondary.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:hover:not(.btn-text), .btn.btn-hover-transparent-secondary:focus:not(.btn-text), .btn.btn-hover-transparent-secondary.focus:not(.btn-text) {
  color: #E5EAEE;
  background-color: rgba(229, 234, 238, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-secondary:hover:not(.btn-text) i, .btn.btn-hover-transparent-secondary:focus:not(.btn-text) i, .btn.btn-hover-transparent-secondary.focus:not(.btn-text) i {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary.disabled, .btn.btn-hover-transparent-secondary:disabled {
  color: #E5EAEE;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-secondary.disabled i, .btn.btn-hover-transparent-secondary:disabled i {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-transparent-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-secondary.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-secondary:disabled.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle,
.show .btn.btn-hover-transparent-secondary.btn-dropdown {
  color: #E5EAEE;
  background-color: rgba(229, 234, 238, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle i,
.show .btn.btn-hover-transparent-secondary.btn-dropdown i {
  color: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-secondary.btn-dropdown.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-light-secondary:hover:not(.btn-text), .btn.btn-hover-light-secondary:focus:not(.btn-text), .btn.btn-hover-light-secondary.focus:not(.btn-text) {
  color: #E5EAEE;
  background-color: #ECF0F3;
  border-color: transparent;
}

.btn.btn-hover-light-secondary:hover:not(.btn-text) i, .btn.btn-hover-light-secondary:focus:not(.btn-text) i, .btn.btn-hover-light-secondary.focus:not(.btn-text) i {
  color: #E5EAEE;
}

.btn.btn-hover-light-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-light-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-secondary.dropdown-toggle,
.show .btn.btn-hover-light-secondary.btn-dropdown {
  color: #E5EAEE;
  background-color: #ECF0F3;
  border-color: transparent;
}

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-secondary.dropdown-toggle i,
.show .btn.btn-hover-light-secondary.btn-dropdown i {
  color: #E5EAEE;
}

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-secondary.btn-dropdown.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-light-secondary.btn-clean {
  border: 0 !important;
}

.btn.btn-link-secondary {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #E5EAEE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-secondary i {
  color: #E5EAEE;
}

.btn.btn-link-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-link-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-secondary.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-link-secondary:hover:not(.btn-text), .btn.btn-link-secondary:focus:not(.btn-text), .btn.btn-link-secondary.focus:not(.btn-text) {
  color: #D6D6E0;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-secondary:hover:not(.btn-text) i, .btn.btn-link-secondary:focus:not(.btn-text) i, .btn.btn-link-secondary.focus:not(.btn-text) i {
  color: #D6D6E0;
}

.btn.btn-link-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #D6D6E0;
}

.btn.btn-link-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #D6D6E0;
}

.btn.btn-link-secondary.disabled, .btn.btn-link-secondary:disabled {
  color: #E5EAEE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-secondary.disabled i, .btn.btn-link-secondary:disabled i {
  color: #E5EAEE;
}

.btn.btn-link-secondary.disabled .svg-icon svg g [fill], .btn.btn-link-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-link-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-link-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-secondary.disabled.dropdown-toggle:after, .btn.btn-link-secondary:disabled.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-secondary.dropdown-toggle,
.show .btn.btn-link-secondary.btn-dropdown {
  color: #D6D6E0;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-secondary.dropdown-toggle i,
.show .btn.btn-link-secondary.btn-dropdown i {
  color: #D6D6E0;
}

.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #D6D6E0;
}

.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-secondary.btn-dropdown.dropdown-toggle:after {
  color: #D6D6E0;
}

.btn.btn-hover-secondary:hover:not(.btn-text), .btn.btn-hover-secondary:focus:not(.btn-text), .btn.btn-hover-secondary.focus:not(.btn-text) {
  color: #464E5F !important;
  background-color: #E5EAEE !important;
  border-color: #E5EAEE !important;
}

.btn.btn-hover-secondary:hover:not(.btn-text) i, .btn.btn-hover-secondary:focus:not(.btn-text) i, .btn.btn-hover-secondary.focus:not(.btn-text) i {
  color: #464E5F !important;
}

.btn.btn-hover-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-secondary.dropdown-toggle,
.show .btn.btn-hover-secondary.btn-dropdown {
  color: #464E5F !important;
  background-color: #E5EAEE !important;
  border-color: #E5EAEE !important;
}

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-secondary.dropdown-toggle i,
.show .btn.btn-hover-secondary.btn-dropdown i {
  color: #464E5F !important;
}

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-secondary.btn-dropdown.dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-secondary.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-secondary:hover:not(.btn-text), .btn.btn-hover-text-secondary:focus:not(.btn-text), .btn.btn-hover-text-secondary.focus:not(.btn-text) {
  color: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:hover:not(.btn-text) i, .btn.btn-hover-text-secondary:focus:not(.btn-text) i, .btn.btn-hover-text-secondary.focus:not(.btn-text) i {
  color: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-secondary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-secondary.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-secondary:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-secondary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-secondary.focus:not(.btn-text).dropdown-toggle:after {
  color: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-secondary.dropdown-toggle,
.show .btn.btn-hover-text-secondary.btn-dropdown {
  color: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-secondary.dropdown-toggle i,
.show .btn.btn-hover-text-secondary.btn-dropdown i {
  color: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-secondary.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-secondary.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE !important;
}

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-secondary.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-secondary.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-secondary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-secondary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-secondary.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-secondary.btn-dropdown.dropdown-toggle:after {
  color: #E5EAEE !important;
}

.btn.btn-hover-bg-secondary:hover:not(.btn-text), .btn.btn-hover-bg-secondary:focus:not(.btn-text), .btn.btn-hover-bg-secondary.focus:not(.btn-text) {
  background-color: #E5EAEE !important;
  border-color: #E5EAEE !important;
}

.btn.btn-hover-bg-secondary:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-secondary:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-secondary.dropdown-toggle,
.show .btn.btn-hover-bg-secondary.btn-dropdown {
  background-color: #E5EAEE !important;
  border-color: #E5EAEE !important;
}

.btn.btn-text-secondary {
  color: #E5EAEE;
}

.btn.btn-text-secondary i {
  color: #E5EAEE;
}

.btn.btn-text-secondary .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-text-secondary .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-secondary.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-text-secondary.disabled, .btn.btn-text-secondary:disabled {
  color: #E5EAEE;
}

.btn.btn-text-secondary.disabled i, .btn.btn-text-secondary:disabled i {
  color: #E5EAEE;
}

.btn.btn-text-secondary.disabled .svg-icon svg g [fill], .btn.btn-text-secondary:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-text-secondary.disabled .svg-icon svg:hover g [fill], .btn.btn-text-secondary:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-secondary.disabled.dropdown-toggle:after, .btn.btn-text-secondary:disabled.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-bg-secondary {
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-bg-secondary.disabled, .btn.btn-bg-secondary:disabled {
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-secondary.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
}

.btn.btn-secondary.btn-shadow:hover:not(.btn-text), .btn.btn-secondary.btn-shadow:focus:not(.btn-text), .btn.btn-secondary.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-secondary.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-secondary.btn-shadow-hover:hover:not(.btn-text), .btn.btn-secondary.btn-shadow-hover:focus:not(.btn-text), .btn.btn-secondary.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-secondary.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-secondary.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-secondary.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-success {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-success i {
  color: #ffffff;
}

.btn.btn-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-success.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-success:hover:not(.btn-text), .btn.btn-success:focus:not(.btn-text), .btn.btn-success.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #0BB7AF;
  border-color: #0BB7AF;
}

.btn.btn-success:hover:not(.btn-text) i, .btn.btn-success:focus:not(.btn-text) i, .btn.btn-success.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-success.disabled, .btn.btn-success:disabled {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-success.disabled i, .btn.btn-success:disabled i {
  color: #ffffff;
}

.btn.btn-success.disabled .svg-icon svg g [fill], .btn.btn-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-success.disabled .svg-icon svg:hover g [fill], .btn.btn-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-success.disabled.dropdown-toggle:after, .btn.btn-success:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.dropdown-toggle,
.show .btn.btn-success.btn-dropdown {
  color: #ffffff;
  background-color: #0BB7AF;
  border-color: #0BB7AF;
}

.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-success.dropdown-toggle i,
.show .btn.btn-success.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-success.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-success {
  color: #1BC5BD;
  background-color: transparent;
  border-color: #1BC5BD;
}

.btn.btn-outline-success i {
  color: #1BC5BD;
}

.btn.btn-outline-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-outline-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-outline-success:hover:not(.btn-text), .btn.btn-outline-success:focus:not(.btn-text), .btn.btn-outline-success.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-outline-success:hover:not(.btn-text) i, .btn.btn-outline-success:focus:not(.btn-text) i, .btn.btn-outline-success.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-success.disabled, .btn.btn-outline-success:disabled {
  color: #1BC5BD;
  background-color: transparent;
  border-color: #1BC5BD;
}

.btn.btn-outline-success.disabled i, .btn.btn-outline-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-outline-success.disabled .svg-icon svg g [fill], .btn.btn-outline-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-outline-success.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-success.disabled.dropdown-toggle:after, .btn.btn-outline-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-success.dropdown-toggle,
.show .btn.btn-outline-success.btn-dropdown {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-success.dropdown-toggle i,
.show .btn.btn-outline-success.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-success.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-success {
  color: #1BC5BD;
  background-color: #C9F7F5;
  border-color: transparent;
}

.btn.btn-light-success i {
  color: #1BC5BD;
}

.btn.btn-light-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-light-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-light-success:hover:not(.btn-text), .btn.btn-light-success:focus:not(.btn-text), .btn.btn-light-success.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: transparent;
}

.btn.btn-light-success:hover:not(.btn-text) i, .btn.btn-light-success:focus:not(.btn-text) i, .btn.btn-light-success.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-success.disabled, .btn.btn-light-success:disabled {
  color: #1BC5BD;
  background-color: #C9F7F5;
  border-color: transparent;
}

.btn.btn-light-success.disabled i, .btn.btn-light-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-light-success.disabled .svg-icon svg g [fill], .btn.btn-light-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-light-success.disabled .svg-icon svg:hover g [fill], .btn.btn-light-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-success.disabled.dropdown-toggle:after, .btn.btn-light-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-success.dropdown-toggle,
.show .btn.btn-light-success.btn-dropdown {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: transparent;
}

.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-success.dropdown-toggle i,
.show .btn.btn-light-success.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-success.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-success {
  color: #1BC5BD;
  background-color: rgba(27, 197, 189, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-success i {
  color: #1BC5BD;
}

.btn.btn-transparent-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-transparent-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-transparent-success:hover:not(.btn-text), .btn.btn-transparent-success:focus:not(.btn-text), .btn.btn-transparent-success.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: transparent;
}

.btn.btn-transparent-success:hover:not(.btn-text) i, .btn.btn-transparent-success:focus:not(.btn-text) i, .btn.btn-transparent-success.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-success.disabled, .btn.btn-transparent-success:disabled {
  color: #1BC5BD;
  background-color: rgba(27, 197, 189, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-success.disabled i, .btn.btn-transparent-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-transparent-success.disabled .svg-icon svg g [fill], .btn.btn-transparent-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-transparent-success.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-success.disabled.dropdown-toggle:after, .btn.btn-transparent-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-success.dropdown-toggle,
.show .btn.btn-transparent-success.btn-dropdown {
  color: #ffffff;
  background-color: #1BC5BD;
  border-color: transparent;
}

.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-success.dropdown-toggle i,
.show .btn.btn-transparent-success.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-success.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-success {
  color: #1BC5BD;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-success i {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-transparent-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success:hover:not(.btn-text), .btn.btn-hover-transparent-success:focus:not(.btn-text), .btn.btn-hover-transparent-success.focus:not(.btn-text) {
  color: #1BC5BD;
  background-color: rgba(27, 197, 189, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-success:hover:not(.btn-text) i, .btn.btn-hover-transparent-success:focus:not(.btn-text) i, .btn.btn-hover-transparent-success.focus:not(.btn-text) i {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-transparent-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success.disabled, .btn.btn-hover-transparent-success:disabled {
  color: #1BC5BD;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-success.disabled i, .btn.btn-hover-transparent-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-transparent-success.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-success.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-success.dropdown-toggle,
.show .btn.btn-hover-transparent-success.btn-dropdown {
  color: #1BC5BD;
  background-color: rgba(27, 197, 189, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-success.dropdown-toggle i,
.show .btn.btn-hover-transparent-success.btn-dropdown i {
  color: #1BC5BD;
}

.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-success.btn-dropdown.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-light-success:hover:not(.btn-text), .btn.btn-hover-light-success:focus:not(.btn-text), .btn.btn-hover-light-success.focus:not(.btn-text) {
  color: #1BC5BD;
  background-color: #C9F7F5;
  border-color: transparent;
}

.btn.btn-hover-light-success:hover:not(.btn-text) i, .btn.btn-hover-light-success:focus:not(.btn-text) i, .btn.btn-hover-light-success.focus:not(.btn-text) i {
  color: #1BC5BD;
}

.btn.btn-hover-light-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-light-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-success.dropdown-toggle,
.show .btn.btn-hover-light-success.btn-dropdown {
  color: #1BC5BD;
  background-color: #C9F7F5;
  border-color: transparent;
}

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-success.dropdown-toggle i,
.show .btn.btn-hover-light-success.btn-dropdown i {
  color: #1BC5BD;
}

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-success.btn-dropdown.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-hover-light-success.btn-clean {
  border: 0 !important;
}

.btn.btn-link-success {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #1BC5BD;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-success i {
  color: #1BC5BD;
}

.btn.btn-link-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-link-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-link-success:hover:not(.btn-text), .btn.btn-link-success:focus:not(.btn-text), .btn.btn-link-success.focus:not(.btn-text) {
  color: #0BB7AF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-success:hover:not(.btn-text) i, .btn.btn-link-success:focus:not(.btn-text) i, .btn.btn-link-success.focus:not(.btn-text) i {
  color: #0BB7AF;
}

.btn.btn-link-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #0BB7AF;
}

.btn.btn-link-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #0BB7AF;
}

.btn.btn-link-success.disabled, .btn.btn-link-success:disabled {
  color: #1BC5BD;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-success.disabled i, .btn.btn-link-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-link-success.disabled .svg-icon svg g [fill], .btn.btn-link-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-link-success.disabled .svg-icon svg:hover g [fill], .btn.btn-link-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-success.disabled.dropdown-toggle:after, .btn.btn-link-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-success.dropdown-toggle,
.show .btn.btn-link-success.btn-dropdown {
  color: #0BB7AF;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-success.dropdown-toggle i,
.show .btn.btn-link-success.btn-dropdown i {
  color: #0BB7AF;
}

.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #0BB7AF;
}

.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-success.btn-dropdown.dropdown-toggle:after {
  color: #0BB7AF;
}

.btn.btn-hover-success:hover:not(.btn-text), .btn.btn-hover-success:focus:not(.btn-text), .btn.btn-hover-success.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #1BC5BD !important;
  border-color: #1BC5BD !important;
}

.btn.btn-hover-success:hover:not(.btn-text) i, .btn.btn-hover-success:focus:not(.btn-text) i, .btn.btn-hover-success.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-success.dropdown-toggle,
.show .btn.btn-hover-success.btn-dropdown {
  color: #ffffff !important;
  background-color: #1BC5BD !important;
  border-color: #1BC5BD !important;
}

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-success.dropdown-toggle i,
.show .btn.btn-hover-success.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-success.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-success.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-success:hover:not(.btn-text), .btn.btn-hover-text-success:focus:not(.btn-text), .btn.btn-hover-text-success.focus:not(.btn-text) {
  color: #1BC5BD !important;
}

.btn.btn-hover-text-success:hover:not(.btn-text) i, .btn.btn-hover-text-success:focus:not(.btn-text) i, .btn.btn-hover-text-success.focus:not(.btn-text) i {
  color: #1BC5BD !important;
}

.btn.btn-hover-text-success:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-success:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-success.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD !important;
}

.btn.btn-hover-text-success:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-success:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-success.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-success:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-success:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-success.focus:not(.btn-text).dropdown-toggle:after {
  color: #1BC5BD !important;
}

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-success.dropdown-toggle,
.show .btn.btn-hover-text-success.btn-dropdown {
  color: #1BC5BD !important;
}

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-success:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-success.dropdown-toggle i,
.show .btn.btn-hover-text-success.btn-dropdown i {
  color: #1BC5BD !important;
}

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-success:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-success.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-success.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD !important;
}

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-success:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-success.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-success.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-success:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-success:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-success.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-success.btn-dropdown.dropdown-toggle:after {
  color: #1BC5BD !important;
}

.btn.btn-hover-bg-success:hover:not(.btn-text), .btn.btn-hover-bg-success:focus:not(.btn-text), .btn.btn-hover-bg-success.focus:not(.btn-text) {
  background-color: #1BC5BD !important;
  border-color: #1BC5BD !important;
}

.btn.btn-hover-bg-success:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-success:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-success.dropdown-toggle,
.show .btn.btn-hover-bg-success.btn-dropdown {
  background-color: #1BC5BD !important;
  border-color: #1BC5BD !important;
}

.btn.btn-text-success {
  color: #1BC5BD;
}

.btn.btn-text-success i {
  color: #1BC5BD;
}

.btn.btn-text-success .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-text-success .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-success.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-text-success.disabled, .btn.btn-text-success:disabled {
  color: #1BC5BD;
}

.btn.btn-text-success.disabled i, .btn.btn-text-success:disabled i {
  color: #1BC5BD;
}

.btn.btn-text-success.disabled .svg-icon svg g [fill], .btn.btn-text-success:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #1BC5BD;
}

.btn.btn-text-success.disabled .svg-icon svg:hover g [fill], .btn.btn-text-success:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-success.disabled.dropdown-toggle:after, .btn.btn-text-success:disabled.dropdown-toggle:after {
  color: #1BC5BD;
}

.btn.btn-bg-success {
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-bg-success.disabled, .btn.btn-bg-success:disabled {
  background-color: #1BC5BD;
  border-color: #1BC5BD;
}

.btn.btn-success.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
}

.btn.btn-success.btn-shadow:hover:not(.btn-text), .btn.btn-success.btn-shadow:focus:not(.btn-text), .btn.btn-success.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
}

.btn.btn-success.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
}

.btn.btn-success.btn-shadow-hover:hover:not(.btn-text), .btn.btn-success.btn-shadow-hover:focus:not(.btn-text), .btn.btn-success.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
}

.btn.btn-success.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-success.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-success.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.3) !important;
}

.btn.btn-info {
  color: #ffffff;
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-info i {
  color: #ffffff;
}

.btn.btn-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-info.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-info:hover:not(.btn-text), .btn.btn-info:focus:not(.btn-text), .btn.btn-info.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #7337EE;
  border-color: #7337EE;
}

.btn.btn-info:hover:not(.btn-text) i, .btn.btn-info:focus:not(.btn-text) i, .btn.btn-info.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-info.disabled, .btn.btn-info:disabled {
  color: #ffffff;
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-info.disabled i, .btn.btn-info:disabled i {
  color: #ffffff;
}

.btn.btn-info.disabled .svg-icon svg g [fill], .btn.btn-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-info.disabled .svg-icon svg:hover g [fill], .btn.btn-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-info.disabled.dropdown-toggle:after, .btn.btn-info:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.dropdown-toggle,
.show .btn.btn-info.btn-dropdown {
  color: #ffffff;
  background-color: #7337EE;
  border-color: #7337EE;
}

.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-info.dropdown-toggle i,
.show .btn.btn-info.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-info.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-info {
  color: #8950FC;
  background-color: transparent;
  border-color: #8950FC;
}

.btn.btn-outline-info i {
  color: #8950FC;
}

.btn.btn-outline-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-outline-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-outline-info:hover:not(.btn-text), .btn.btn-outline-info:focus:not(.btn-text), .btn.btn-outline-info.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-outline-info:hover:not(.btn-text) i, .btn.btn-outline-info:focus:not(.btn-text) i, .btn.btn-outline-info.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-info.disabled, .btn.btn-outline-info:disabled {
  color: #8950FC;
  background-color: transparent;
  border-color: #8950FC;
}

.btn.btn-outline-info.disabled i, .btn.btn-outline-info:disabled i {
  color: #8950FC;
}

.btn.btn-outline-info.disabled .svg-icon svg g [fill], .btn.btn-outline-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-outline-info.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-info.disabled.dropdown-toggle:after, .btn.btn-outline-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-info.dropdown-toggle,
.show .btn.btn-outline-info.btn-dropdown {
  color: #ffffff;
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-info.dropdown-toggle i,
.show .btn.btn-outline-info.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-info.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-info {
  color: #8950FC;
  background-color: #EEE5FF;
  border-color: transparent;
}

.btn.btn-light-info i {
  color: #8950FC;
}

.btn.btn-light-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-light-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-light-info:hover:not(.btn-text), .btn.btn-light-info:focus:not(.btn-text), .btn.btn-light-info.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #8950FC;
  border-color: transparent;
}

.btn.btn-light-info:hover:not(.btn-text) i, .btn.btn-light-info:focus:not(.btn-text) i, .btn.btn-light-info.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-info.disabled, .btn.btn-light-info:disabled {
  color: #8950FC;
  background-color: #EEE5FF;
  border-color: transparent;
}

.btn.btn-light-info.disabled i, .btn.btn-light-info:disabled i {
  color: #8950FC;
}

.btn.btn-light-info.disabled .svg-icon svg g [fill], .btn.btn-light-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-light-info.disabled .svg-icon svg:hover g [fill], .btn.btn-light-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-info.disabled.dropdown-toggle:after, .btn.btn-light-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-info.dropdown-toggle,
.show .btn.btn-light-info.btn-dropdown {
  color: #ffffff;
  background-color: #8950FC;
  border-color: transparent;
}

.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-info.dropdown-toggle i,
.show .btn.btn-light-info.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-info.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-info {
  color: #8950FC;
  background-color: rgba(137, 80, 252, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-info i {
  color: #8950FC;
}

.btn.btn-transparent-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-transparent-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-transparent-info:hover:not(.btn-text), .btn.btn-transparent-info:focus:not(.btn-text), .btn.btn-transparent-info.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #8950FC;
  border-color: transparent;
}

.btn.btn-transparent-info:hover:not(.btn-text) i, .btn.btn-transparent-info:focus:not(.btn-text) i, .btn.btn-transparent-info.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-info.disabled, .btn.btn-transparent-info:disabled {
  color: #8950FC;
  background-color: rgba(137, 80, 252, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-info.disabled i, .btn.btn-transparent-info:disabled i {
  color: #8950FC;
}

.btn.btn-transparent-info.disabled .svg-icon svg g [fill], .btn.btn-transparent-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-transparent-info.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-info.disabled.dropdown-toggle:after, .btn.btn-transparent-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-info.dropdown-toggle,
.show .btn.btn-transparent-info.btn-dropdown {
  color: #ffffff;
  background-color: #8950FC;
  border-color: transparent;
}

.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-info.dropdown-toggle i,
.show .btn.btn-transparent-info.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-info.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-info {
  color: #8950FC;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-info i {
  color: #8950FC;
}

.btn.btn-hover-transparent-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-transparent-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-transparent-info:hover:not(.btn-text), .btn.btn-hover-transparent-info:focus:not(.btn-text), .btn.btn-hover-transparent-info.focus:not(.btn-text) {
  color: #8950FC;
  background-color: rgba(137, 80, 252, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-info:hover:not(.btn-text) i, .btn.btn-hover-transparent-info:focus:not(.btn-text) i, .btn.btn-hover-transparent-info.focus:not(.btn-text) i {
  color: #8950FC;
}

.btn.btn-hover-transparent-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-transparent-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-transparent-info.disabled, .btn.btn-hover-transparent-info:disabled {
  color: #8950FC;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-info.disabled i, .btn.btn-hover-transparent-info:disabled i {
  color: #8950FC;
}

.btn.btn-hover-transparent-info.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-transparent-info.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-info.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-info.dropdown-toggle,
.show .btn.btn-hover-transparent-info.btn-dropdown {
  color: #8950FC;
  background-color: rgba(137, 80, 252, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-info.dropdown-toggle i,
.show .btn.btn-hover-transparent-info.btn-dropdown i {
  color: #8950FC;
}

.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-info.btn-dropdown.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-light-info:hover:not(.btn-text), .btn.btn-hover-light-info:focus:not(.btn-text), .btn.btn-hover-light-info.focus:not(.btn-text) {
  color: #8950FC;
  background-color: #EEE5FF;
  border-color: transparent;
}

.btn.btn-hover-light-info:hover:not(.btn-text) i, .btn.btn-hover-light-info:focus:not(.btn-text) i, .btn.btn-hover-light-info.focus:not(.btn-text) i {
  color: #8950FC;
}

.btn.btn-hover-light-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-light-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-info.dropdown-toggle,
.show .btn.btn-hover-light-info.btn-dropdown {
  color: #8950FC;
  background-color: #EEE5FF;
  border-color: transparent;
}

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-info.dropdown-toggle i,
.show .btn.btn-hover-light-info.btn-dropdown i {
  color: #8950FC;
}

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-info.btn-dropdown.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-hover-light-info.btn-clean {
  border: 0 !important;
}

.btn.btn-link-info {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #8950FC;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-info i {
  color: #8950FC;
}

.btn.btn-link-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-link-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-link-info:hover:not(.btn-text), .btn.btn-link-info:focus:not(.btn-text), .btn.btn-link-info.focus:not(.btn-text) {
  color: #7337EE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-info:hover:not(.btn-text) i, .btn.btn-link-info:focus:not(.btn-text) i, .btn.btn-link-info.focus:not(.btn-text) i {
  color: #7337EE;
}

.btn.btn-link-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #7337EE;
}

.btn.btn-link-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #7337EE;
}

.btn.btn-link-info.disabled, .btn.btn-link-info:disabled {
  color: #8950FC;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-info.disabled i, .btn.btn-link-info:disabled i {
  color: #8950FC;
}

.btn.btn-link-info.disabled .svg-icon svg g [fill], .btn.btn-link-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-link-info.disabled .svg-icon svg:hover g [fill], .btn.btn-link-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-info.disabled.dropdown-toggle:after, .btn.btn-link-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-info.dropdown-toggle,
.show .btn.btn-link-info.btn-dropdown {
  color: #7337EE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-info.dropdown-toggle i,
.show .btn.btn-link-info.btn-dropdown i {
  color: #7337EE;
}

.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #7337EE;
}

.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-info.btn-dropdown.dropdown-toggle:after {
  color: #7337EE;
}

.btn.btn-hover-info:hover:not(.btn-text), .btn.btn-hover-info:focus:not(.btn-text), .btn.btn-hover-info.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #8950FC !important;
  border-color: #8950FC !important;
}

.btn.btn-hover-info:hover:not(.btn-text) i, .btn.btn-hover-info:focus:not(.btn-text) i, .btn.btn-hover-info.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-info.dropdown-toggle,
.show .btn.btn-hover-info.btn-dropdown {
  color: #ffffff !important;
  background-color: #8950FC !important;
  border-color: #8950FC !important;
}

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-info.dropdown-toggle i,
.show .btn.btn-hover-info.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-info.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-info.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-info:hover:not(.btn-text), .btn.btn-hover-text-info:focus:not(.btn-text), .btn.btn-hover-text-info.focus:not(.btn-text) {
  color: #8950FC !important;
}

.btn.btn-hover-text-info:hover:not(.btn-text) i, .btn.btn-hover-text-info:focus:not(.btn-text) i, .btn.btn-hover-text-info.focus:not(.btn-text) i {
  color: #8950FC !important;
}

.btn.btn-hover-text-info:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-info:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-info.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC !important;
}

.btn.btn-hover-text-info:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-info:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-info.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-info:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-info:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-info.focus:not(.btn-text).dropdown-toggle:after {
  color: #8950FC !important;
}

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-info.dropdown-toggle,
.show .btn.btn-hover-text-info.btn-dropdown {
  color: #8950FC !important;
}

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-info:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-info.dropdown-toggle i,
.show .btn.btn-hover-text-info.btn-dropdown i {
  color: #8950FC !important;
}

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-info:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-info.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-info.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC !important;
}

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-info:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-info.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-info.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-info:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-info:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-info.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-info.btn-dropdown.dropdown-toggle:after {
  color: #8950FC !important;
}

.btn.btn-hover-bg-info:hover:not(.btn-text), .btn.btn-hover-bg-info:focus:not(.btn-text), .btn.btn-hover-bg-info.focus:not(.btn-text) {
  background-color: #8950FC !important;
  border-color: #8950FC !important;
}

.btn.btn-hover-bg-info:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-info:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-info.dropdown-toggle,
.show .btn.btn-hover-bg-info.btn-dropdown {
  background-color: #8950FC !important;
  border-color: #8950FC !important;
}

.btn.btn-text-info {
  color: #8950FC;
}

.btn.btn-text-info i {
  color: #8950FC;
}

.btn.btn-text-info .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-text-info .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-info.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-text-info.disabled, .btn.btn-text-info:disabled {
  color: #8950FC;
}

.btn.btn-text-info.disabled i, .btn.btn-text-info:disabled i {
  color: #8950FC;
}

.btn.btn-text-info.disabled .svg-icon svg g [fill], .btn.btn-text-info:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #8950FC;
}

.btn.btn-text-info.disabled .svg-icon svg:hover g [fill], .btn.btn-text-info:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-info.disabled.dropdown-toggle:after, .btn.btn-text-info:disabled.dropdown-toggle:after {
  color: #8950FC;
}

.btn.btn-bg-info {
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-bg-info.disabled, .btn.btn-bg-info:disabled {
  background-color: #8950FC;
  border-color: #8950FC;
}

.btn.btn-info.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.25) !important;
}

.btn.btn-info.btn-shadow:hover:not(.btn-text), .btn.btn-info.btn-shadow:focus:not(.btn-text), .btn.btn-info.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
}

.btn.btn-info.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
}

.btn.btn-info.btn-shadow-hover:hover:not(.btn-text), .btn.btn-info.btn-shadow-hover:focus:not(.btn-text), .btn.btn-info.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
}

.btn.btn-info.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-info.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-info.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(137, 80, 252, 0.3) !important;
}

.btn.btn-warning {
  color: #ffffff;
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-warning i {
  color: #ffffff;
}

.btn.btn-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-warning.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-warning:hover:not(.btn-text), .btn.btn-warning:focus:not(.btn-text), .btn.btn-warning.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #EE9D01;
  border-color: #EE9D01;
}

.btn.btn-warning:hover:not(.btn-text) i, .btn.btn-warning:focus:not(.btn-text) i, .btn.btn-warning.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-warning.disabled, .btn.btn-warning:disabled {
  color: #ffffff;
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-warning.disabled i, .btn.btn-warning:disabled i {
  color: #ffffff;
}

.btn.btn-warning.disabled .svg-icon svg g [fill], .btn.btn-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-warning.disabled.dropdown-toggle:after, .btn.btn-warning:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.dropdown-toggle,
.show .btn.btn-warning.btn-dropdown {
  color: #ffffff;
  background-color: #EE9D01;
  border-color: #EE9D01;
}

.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-warning.dropdown-toggle i,
.show .btn.btn-warning.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-warning.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-warning {
  color: #FFA800;
  background-color: transparent;
  border-color: #FFA800;
}

.btn.btn-outline-warning i {
  color: #FFA800;
}

.btn.btn-outline-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-outline-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-outline-warning:hover:not(.btn-text), .btn.btn-outline-warning:focus:not(.btn-text), .btn.btn-outline-warning.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-outline-warning:hover:not(.btn-text) i, .btn.btn-outline-warning:focus:not(.btn-text) i, .btn.btn-outline-warning.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-warning.disabled, .btn.btn-outline-warning:disabled {
  color: #FFA800;
  background-color: transparent;
  border-color: #FFA800;
}

.btn.btn-outline-warning.disabled i, .btn.btn-outline-warning:disabled i {
  color: #FFA800;
}

.btn.btn-outline-warning.disabled .svg-icon svg g [fill], .btn.btn-outline-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-outline-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-warning.disabled.dropdown-toggle:after, .btn.btn-outline-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-warning.dropdown-toggle,
.show .btn.btn-outline-warning.btn-dropdown {
  color: #ffffff;
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-warning.dropdown-toggle i,
.show .btn.btn-outline-warning.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-warning.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-warning {
  color: #FFA800;
  background-color: #FFF4DE;
  border-color: transparent;
}

.btn.btn-light-warning i {
  color: #FFA800;
}

.btn.btn-light-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-light-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-light-warning:hover:not(.btn-text), .btn.btn-light-warning:focus:not(.btn-text), .btn.btn-light-warning.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #FFA800;
  border-color: transparent;
}

.btn.btn-light-warning:hover:not(.btn-text) i, .btn.btn-light-warning:focus:not(.btn-text) i, .btn.btn-light-warning.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-warning.disabled, .btn.btn-light-warning:disabled {
  color: #FFA800;
  background-color: #FFF4DE;
  border-color: transparent;
}

.btn.btn-light-warning.disabled i, .btn.btn-light-warning:disabled i {
  color: #FFA800;
}

.btn.btn-light-warning.disabled .svg-icon svg g [fill], .btn.btn-light-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-light-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-light-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-warning.disabled.dropdown-toggle:after, .btn.btn-light-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-warning.dropdown-toggle,
.show .btn.btn-light-warning.btn-dropdown {
  color: #ffffff;
  background-color: #FFA800;
  border-color: transparent;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-warning.dropdown-toggle i,
.show .btn.btn-light-warning.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-warning.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-warning {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-warning i {
  color: #FFA800;
}

.btn.btn-transparent-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-transparent-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-transparent-warning:hover:not(.btn-text), .btn.btn-transparent-warning:focus:not(.btn-text), .btn.btn-transparent-warning.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #FFA800;
  border-color: transparent;
}

.btn.btn-transparent-warning:hover:not(.btn-text) i, .btn.btn-transparent-warning:focus:not(.btn-text) i, .btn.btn-transparent-warning.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-warning.disabled, .btn.btn-transparent-warning:disabled {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-warning.disabled i, .btn.btn-transparent-warning:disabled i {
  color: #FFA800;
}

.btn.btn-transparent-warning.disabled .svg-icon svg g [fill], .btn.btn-transparent-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-transparent-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-warning.disabled.dropdown-toggle:after, .btn.btn-transparent-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-warning.dropdown-toggle,
.show .btn.btn-transparent-warning.btn-dropdown {
  color: #ffffff;
  background-color: #FFA800;
  border-color: transparent;
}

.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-warning.dropdown-toggle i,
.show .btn.btn-transparent-warning.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-warning.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-warning {
  color: #FFA800;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-warning i {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-transparent-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning:hover:not(.btn-text), .btn.btn-hover-transparent-warning:focus:not(.btn-text), .btn.btn-hover-transparent-warning.focus:not(.btn-text) {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-warning:hover:not(.btn-text) i, .btn.btn-hover-transparent-warning:focus:not(.btn-text) i, .btn.btn-hover-transparent-warning.focus:not(.btn-text) i {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-transparent-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning.disabled, .btn.btn-hover-transparent-warning:disabled {
  color: #FFA800;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-warning.disabled i, .btn.btn-hover-transparent-warning:disabled i {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-transparent-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-warning.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle,
.show .btn.btn-hover-transparent-warning.btn-dropdown {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle i,
.show .btn.btn-hover-transparent-warning.btn-dropdown i {
  color: #FFA800;
}

.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-warning.btn-dropdown.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-light-warning:hover:not(.btn-text), .btn.btn-hover-light-warning:focus:not(.btn-text), .btn.btn-hover-light-warning.focus:not(.btn-text) {
  color: #FFA800;
  background-color: #FFF4DE;
  border-color: transparent;
}

.btn.btn-hover-light-warning:hover:not(.btn-text) i, .btn.btn-hover-light-warning:focus:not(.btn-text) i, .btn.btn-hover-light-warning.focus:not(.btn-text) i {
  color: #FFA800;
}

.btn.btn-hover-light-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-light-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-warning.dropdown-toggle,
.show .btn.btn-hover-light-warning.btn-dropdown {
  color: #FFA800;
  background-color: #FFF4DE;
  border-color: transparent;
}

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-warning.dropdown-toggle i,
.show .btn.btn-hover-light-warning.btn-dropdown i {
  color: #FFA800;
}

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-warning.btn-dropdown.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-hover-light-warning.btn-clean {
  border: 0 !important;
}

.btn.btn-link-warning {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #FFA800;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-warning i {
  color: #FFA800;
}

.btn.btn-link-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-link-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-link-warning:hover:not(.btn-text), .btn.btn-link-warning:focus:not(.btn-text), .btn.btn-link-warning.focus:not(.btn-text) {
  color: #EE9D01;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-warning:hover:not(.btn-text) i, .btn.btn-link-warning:focus:not(.btn-text) i, .btn.btn-link-warning.focus:not(.btn-text) i {
  color: #EE9D01;
}

.btn.btn-link-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #EE9D01;
}

.btn.btn-link-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #EE9D01;
}

.btn.btn-link-warning.disabled, .btn.btn-link-warning:disabled {
  color: #FFA800;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-warning.disabled i, .btn.btn-link-warning:disabled i {
  color: #FFA800;
}

.btn.btn-link-warning.disabled .svg-icon svg g [fill], .btn.btn-link-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-link-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-link-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-warning.disabled.dropdown-toggle:after, .btn.btn-link-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-warning.dropdown-toggle,
.show .btn.btn-link-warning.btn-dropdown {
  color: #EE9D01;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-warning.dropdown-toggle i,
.show .btn.btn-link-warning.btn-dropdown i {
  color: #EE9D01;
}

.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #EE9D01;
}

.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-warning.btn-dropdown.dropdown-toggle:after {
  color: #EE9D01;
}

.btn.btn-hover-warning:hover:not(.btn-text), .btn.btn-hover-warning:focus:not(.btn-text), .btn.btn-hover-warning.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #FFA800 !important;
  border-color: #FFA800 !important;
}

.btn.btn-hover-warning:hover:not(.btn-text) i, .btn.btn-hover-warning:focus:not(.btn-text) i, .btn.btn-hover-warning.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-warning.dropdown-toggle,
.show .btn.btn-hover-warning.btn-dropdown {
  color: #ffffff !important;
  background-color: #FFA800 !important;
  border-color: #FFA800 !important;
}

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-warning.dropdown-toggle i,
.show .btn.btn-hover-warning.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-warning.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-warning.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-warning:hover:not(.btn-text), .btn.btn-hover-text-warning:focus:not(.btn-text), .btn.btn-hover-text-warning.focus:not(.btn-text) {
  color: #FFA800 !important;
}

.btn.btn-hover-text-warning:hover:not(.btn-text) i, .btn.btn-hover-text-warning:focus:not(.btn-text) i, .btn.btn-hover-text-warning.focus:not(.btn-text) i {
  color: #FFA800 !important;
}

.btn.btn-hover-text-warning:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-warning:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-warning.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800 !important;
}

.btn.btn-hover-text-warning:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-warning:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-warning.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-warning:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-warning:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-warning.focus:not(.btn-text).dropdown-toggle:after {
  color: #FFA800 !important;
}

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-warning.dropdown-toggle,
.show .btn.btn-hover-text-warning.btn-dropdown {
  color: #FFA800 !important;
}

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-warning.dropdown-toggle i,
.show .btn.btn-hover-text-warning.btn-dropdown i {
  color: #FFA800 !important;
}

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-warning.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-warning.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800 !important;
}

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-warning.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-warning.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-warning:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-warning:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-warning.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-warning.btn-dropdown.dropdown-toggle:after {
  color: #FFA800 !important;
}

.btn.btn-hover-bg-warning:hover:not(.btn-text), .btn.btn-hover-bg-warning:focus:not(.btn-text), .btn.btn-hover-bg-warning.focus:not(.btn-text) {
  background-color: #FFA800 !important;
  border-color: #FFA800 !important;
}

.btn.btn-hover-bg-warning:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-warning:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-warning.dropdown-toggle,
.show .btn.btn-hover-bg-warning.btn-dropdown {
  background-color: #FFA800 !important;
  border-color: #FFA800 !important;
}

.btn.btn-text-warning {
  color: #FFA800;
}

.btn.btn-text-warning i {
  color: #FFA800;
}

.btn.btn-text-warning .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-text-warning .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-text-warning.disabled, .btn.btn-text-warning:disabled {
  color: #FFA800;
}

.btn.btn-text-warning.disabled i, .btn.btn-text-warning:disabled i {
  color: #FFA800;
}

.btn.btn-text-warning.disabled .svg-icon svg g [fill], .btn.btn-text-warning:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #FFA800;
}

.btn.btn-text-warning.disabled .svg-icon svg:hover g [fill], .btn.btn-text-warning:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-warning.disabled.dropdown-toggle:after, .btn.btn-text-warning:disabled.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-bg-warning {
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-bg-warning.disabled, .btn.btn-bg-warning:disabled {
  background-color: #FFA800;
  border-color: #FFA800;
}

.btn.btn-warning.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.25) !important;
}

.btn.btn-warning.btn-shadow:hover:not(.btn-text), .btn.btn-warning.btn-shadow:focus:not(.btn-text), .btn.btn-warning.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
}

.btn.btn-warning.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
}

.btn.btn-warning.btn-shadow-hover:hover:not(.btn-text), .btn.btn-warning.btn-shadow-hover:focus:not(.btn-text), .btn.btn-warning.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
}

.btn.btn-warning.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-warning.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-warning.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(255, 168, 0, 0.3) !important;
}

.btn.btn-danger {
  color: #ffffff;
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-danger i {
  color: #ffffff;
}

.btn.btn-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-danger.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-danger:hover:not(.btn-text), .btn.btn-danger:focus:not(.btn-text), .btn.btn-danger.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #EE2D41;
  border-color: #EE2D41;
}

.btn.btn-danger:hover:not(.btn-text) i, .btn.btn-danger:focus:not(.btn-text) i, .btn.btn-danger.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-danger.disabled, .btn.btn-danger:disabled {
  color: #ffffff;
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-danger.disabled i, .btn.btn-danger:disabled i {
  color: #ffffff;
}

.btn.btn-danger.disabled .svg-icon svg g [fill], .btn.btn-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-danger.disabled.dropdown-toggle:after, .btn.btn-danger:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.dropdown-toggle,
.show .btn.btn-danger.btn-dropdown {
  color: #ffffff;
  background-color: #EE2D41;
  border-color: #EE2D41;
}

.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-danger.dropdown-toggle i,
.show .btn.btn-danger.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-danger.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-danger {
  color: #F64E60;
  background-color: transparent;
  border-color: #F64E60;
}

.btn.btn-outline-danger i {
  color: #F64E60;
}

.btn.btn-outline-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-outline-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-outline-danger:hover:not(.btn-text), .btn.btn-outline-danger:focus:not(.btn-text), .btn.btn-outline-danger.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-outline-danger:hover:not(.btn-text) i, .btn.btn-outline-danger:focus:not(.btn-text) i, .btn.btn-outline-danger.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-danger.disabled, .btn.btn-outline-danger:disabled {
  color: #F64E60;
  background-color: transparent;
  border-color: #F64E60;
}

.btn.btn-outline-danger.disabled i, .btn.btn-outline-danger:disabled i {
  color: #F64E60;
}

.btn.btn-outline-danger.disabled .svg-icon svg g [fill], .btn.btn-outline-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-outline-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-danger.disabled.dropdown-toggle:after, .btn.btn-outline-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-danger.dropdown-toggle,
.show .btn.btn-outline-danger.btn-dropdown {
  color: #ffffff;
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-danger.dropdown-toggle i,
.show .btn.btn-outline-danger.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-danger.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-danger {
  color: #F64E60;
  background-color: #FFE2E5;
  border-color: transparent;
}

.btn.btn-light-danger i {
  color: #F64E60;
}

.btn.btn-light-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-light-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-light-danger:hover:not(.btn-text), .btn.btn-light-danger:focus:not(.btn-text), .btn.btn-light-danger.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #F64E60;
  border-color: transparent;
}

.btn.btn-light-danger:hover:not(.btn-text) i, .btn.btn-light-danger:focus:not(.btn-text) i, .btn.btn-light-danger.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-danger.disabled, .btn.btn-light-danger:disabled {
  color: #F64E60;
  background-color: #FFE2E5;
  border-color: transparent;
}

.btn.btn-light-danger.disabled i, .btn.btn-light-danger:disabled i {
  color: #F64E60;
}

.btn.btn-light-danger.disabled .svg-icon svg g [fill], .btn.btn-light-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-light-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-light-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-danger.disabled.dropdown-toggle:after, .btn.btn-light-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-danger.dropdown-toggle,
.show .btn.btn-light-danger.btn-dropdown {
  color: #ffffff;
  background-color: #F64E60;
  border-color: transparent;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-danger.dropdown-toggle i,
.show .btn.btn-light-danger.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-danger.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-danger {
  color: #F64E60;
  background-color: rgba(246, 78, 96, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-danger i {
  color: #F64E60;
}

.btn.btn-transparent-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-transparent-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-transparent-danger:hover:not(.btn-text), .btn.btn-transparent-danger:focus:not(.btn-text), .btn.btn-transparent-danger.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #F64E60;
  border-color: transparent;
}

.btn.btn-transparent-danger:hover:not(.btn-text) i, .btn.btn-transparent-danger:focus:not(.btn-text) i, .btn.btn-transparent-danger.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-danger.disabled, .btn.btn-transparent-danger:disabled {
  color: #F64E60;
  background-color: rgba(246, 78, 96, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-danger.disabled i, .btn.btn-transparent-danger:disabled i {
  color: #F64E60;
}

.btn.btn-transparent-danger.disabled .svg-icon svg g [fill], .btn.btn-transparent-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-transparent-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-danger.disabled.dropdown-toggle:after, .btn.btn-transparent-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-danger.dropdown-toggle,
.show .btn.btn-transparent-danger.btn-dropdown {
  color: #ffffff;
  background-color: #F64E60;
  border-color: transparent;
}

.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-danger.dropdown-toggle i,
.show .btn.btn-transparent-danger.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-danger.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-danger {
  color: #F64E60;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-danger i {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-transparent-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger:hover:not(.btn-text), .btn.btn-hover-transparent-danger:focus:not(.btn-text), .btn.btn-hover-transparent-danger.focus:not(.btn-text) {
  color: #F64E60;
  background-color: rgba(246, 78, 96, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-danger:hover:not(.btn-text) i, .btn.btn-hover-transparent-danger:focus:not(.btn-text) i, .btn.btn-hover-transparent-danger.focus:not(.btn-text) i {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-transparent-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger.disabled, .btn.btn-hover-transparent-danger:disabled {
  color: #F64E60;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-danger.disabled i, .btn.btn-hover-transparent-danger:disabled i {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-transparent-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-danger.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle,
.show .btn.btn-hover-transparent-danger.btn-dropdown {
  color: #F64E60;
  background-color: rgba(246, 78, 96, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle i,
.show .btn.btn-hover-transparent-danger.btn-dropdown i {
  color: #F64E60;
}

.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-danger.btn-dropdown.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-light-danger:hover:not(.btn-text), .btn.btn-hover-light-danger:focus:not(.btn-text), .btn.btn-hover-light-danger.focus:not(.btn-text) {
  color: #F64E60;
  background-color: #FFE2E5;
  border-color: transparent;
}

.btn.btn-hover-light-danger:hover:not(.btn-text) i, .btn.btn-hover-light-danger:focus:not(.btn-text) i, .btn.btn-hover-light-danger.focus:not(.btn-text) i {
  color: #F64E60;
}

.btn.btn-hover-light-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-light-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-danger.dropdown-toggle,
.show .btn.btn-hover-light-danger.btn-dropdown {
  color: #F64E60;
  background-color: #FFE2E5;
  border-color: transparent;
}

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-danger.dropdown-toggle i,
.show .btn.btn-hover-light-danger.btn-dropdown i {
  color: #F64E60;
}

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-danger.btn-dropdown.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-hover-light-danger.btn-clean {
  border: 0 !important;
}

.btn.btn-link-danger {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #F64E60;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-danger i {
  color: #F64E60;
}

.btn.btn-link-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-link-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-link-danger:hover:not(.btn-text), .btn.btn-link-danger:focus:not(.btn-text), .btn.btn-link-danger.focus:not(.btn-text) {
  color: #EE2D41;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-danger:hover:not(.btn-text) i, .btn.btn-link-danger:focus:not(.btn-text) i, .btn.btn-link-danger.focus:not(.btn-text) i {
  color: #EE2D41;
}

.btn.btn-link-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #EE2D41;
}

.btn.btn-link-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #EE2D41;
}

.btn.btn-link-danger.disabled, .btn.btn-link-danger:disabled {
  color: #F64E60;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-danger.disabled i, .btn.btn-link-danger:disabled i {
  color: #F64E60;
}

.btn.btn-link-danger.disabled .svg-icon svg g [fill], .btn.btn-link-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-link-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-link-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-danger.disabled.dropdown-toggle:after, .btn.btn-link-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-danger.dropdown-toggle,
.show .btn.btn-link-danger.btn-dropdown {
  color: #EE2D41;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-danger.dropdown-toggle i,
.show .btn.btn-link-danger.btn-dropdown i {
  color: #EE2D41;
}

.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #EE2D41;
}

.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-danger.btn-dropdown.dropdown-toggle:after {
  color: #EE2D41;
}

.btn.btn-hover-danger:hover:not(.btn-text), .btn.btn-hover-danger:focus:not(.btn-text), .btn.btn-hover-danger.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #F64E60 !important;
  border-color: #F64E60 !important;
}

.btn.btn-hover-danger:hover:not(.btn-text) i, .btn.btn-hover-danger:focus:not(.btn-text) i, .btn.btn-hover-danger.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-danger.dropdown-toggle,
.show .btn.btn-hover-danger.btn-dropdown {
  color: #ffffff !important;
  background-color: #F64E60 !important;
  border-color: #F64E60 !important;
}

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-danger.dropdown-toggle i,
.show .btn.btn-hover-danger.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-danger.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-danger.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-danger:hover:not(.btn-text), .btn.btn-hover-text-danger:focus:not(.btn-text), .btn.btn-hover-text-danger.focus:not(.btn-text) {
  color: #F64E60 !important;
}

.btn.btn-hover-text-danger:hover:not(.btn-text) i, .btn.btn-hover-text-danger:focus:not(.btn-text) i, .btn.btn-hover-text-danger.focus:not(.btn-text) i {
  color: #F64E60 !important;
}

.btn.btn-hover-text-danger:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-danger:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-danger.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60 !important;
}

.btn.btn-hover-text-danger:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-danger:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-danger.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-danger:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-danger:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-danger.focus:not(.btn-text).dropdown-toggle:after {
  color: #F64E60 !important;
}

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-danger.dropdown-toggle,
.show .btn.btn-hover-text-danger.btn-dropdown {
  color: #F64E60 !important;
}

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-danger.dropdown-toggle i,
.show .btn.btn-hover-text-danger.btn-dropdown i {
  color: #F64E60 !important;
}

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-danger.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-danger.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60 !important;
}

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-danger.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-danger.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-danger:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-danger:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-danger.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-danger.btn-dropdown.dropdown-toggle:after {
  color: #F64E60 !important;
}

.btn.btn-hover-bg-danger:hover:not(.btn-text), .btn.btn-hover-bg-danger:focus:not(.btn-text), .btn.btn-hover-bg-danger.focus:not(.btn-text) {
  background-color: #F64E60 !important;
  border-color: #F64E60 !important;
}

.btn.btn-hover-bg-danger:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-danger:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-danger.dropdown-toggle,
.show .btn.btn-hover-bg-danger.btn-dropdown {
  background-color: #F64E60 !important;
  border-color: #F64E60 !important;
}

.btn.btn-text-danger {
  color: #F64E60;
}

.btn.btn-text-danger i {
  color: #F64E60;
}

.btn.btn-text-danger .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-text-danger .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-danger.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-text-danger.disabled, .btn.btn-text-danger:disabled {
  color: #F64E60;
}

.btn.btn-text-danger.disabled i, .btn.btn-text-danger:disabled i {
  color: #F64E60;
}

.btn.btn-text-danger.disabled .svg-icon svg g [fill], .btn.btn-text-danger:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F64E60;
}

.btn.btn-text-danger.disabled .svg-icon svg:hover g [fill], .btn.btn-text-danger:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-danger.disabled.dropdown-toggle:after, .btn.btn-text-danger:disabled.dropdown-toggle:after {
  color: #F64E60;
}

.btn.btn-bg-danger {
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-bg-danger.disabled, .btn.btn-bg-danger:disabled {
  background-color: #F64E60;
  border-color: #F64E60;
}

.btn.btn-danger.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.25) !important;
}

.btn.btn-danger.btn-shadow:hover:not(.btn-text), .btn.btn-danger.btn-shadow:focus:not(.btn-text), .btn.btn-danger.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
}

.btn.btn-danger.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
}

.btn.btn-danger.btn-shadow-hover:hover:not(.btn-text), .btn.btn-danger.btn-shadow-hover:focus:not(.btn-text), .btn.btn-danger.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
}

.btn.btn-danger.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-danger.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-danger.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(246, 78, 96, 0.3) !important;
}

.btn.btn-light {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-light i {
  color: #80808F;
}

.btn.btn-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-light:hover:not(.btn-text), .btn.btn-light:focus:not(.btn-text), .btn.btn-light.focus:not(.btn-text) {
  color: #80808F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-light:hover:not(.btn-text) i, .btn.btn-light:focus:not(.btn-text) i, .btn.btn-light.focus:not(.btn-text) i {
  color: #80808F;
}

.btn.btn-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-light.disabled, .btn.btn-light:disabled {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-light.disabled i, .btn.btn-light:disabled i {
  color: #80808F;
}

.btn.btn-light.disabled .svg-icon svg g [fill], .btn.btn-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light.disabled .svg-icon svg:hover g [fill], .btn.btn-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light.disabled.dropdown-toggle:after, .btn.btn-light:disabled.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.dropdown-toggle,
.show .btn.btn-light.btn-dropdown {
  color: #80808F;
  background-color: #E5EAEE;
  border-color: #E5EAEE;
}

.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light.dropdown-toggle i,
.show .btn.btn-light.btn-dropdown i {
  color: #80808F;
}

.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light.btn-dropdown.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-outline-light {
  color: #464E5F;
  background-color: transparent;
  border-color: #F3F6F9;
}

.btn.btn-outline-light i {
  color: #464E5F;
}

.btn.btn-outline-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-light.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-light:hover:not(.btn-text), .btn.btn-outline-light:focus:not(.btn-text), .btn.btn-outline-light.focus:not(.btn-text) {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-outline-light:hover:not(.btn-text) i, .btn.btn-outline-light:focus:not(.btn-text) i, .btn.btn-outline-light.focus:not(.btn-text) i {
  color: #80808F;
}

.btn.btn-outline-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-outline-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-outline-light.disabled, .btn.btn-outline-light:disabled {
  color: #464E5F;
  background-color: transparent;
  border-color: #F3F6F9;
}

.btn.btn-outline-light.disabled i, .btn.btn-outline-light:disabled i {
  color: #464E5F;
}

.btn.btn-outline-light.disabled .svg-icon svg g [fill], .btn.btn-outline-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-light.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-light.disabled.dropdown-toggle:after, .btn.btn-outline-light:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-light.dropdown-toggle,
.show .btn.btn-outline-light.btn-dropdown {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-light.dropdown-toggle i,
.show .btn.btn-outline-light.btn-dropdown i {
  color: #80808F;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-light.btn-dropdown.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-light-light {
  color: #F3F6F9;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-light-light i {
  color: #F3F6F9;
}

.btn.btn-light-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-light-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-light.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-light-light:hover:not(.btn-text), .btn.btn-light-light:focus:not(.btn-text), .btn.btn-light-light.focus:not(.btn-text) {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-light-light:hover:not(.btn-text) i, .btn.btn-light-light:focus:not(.btn-text) i, .btn.btn-light-light.focus:not(.btn-text) i {
  color: #80808F;
}

.btn.btn-light-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-light-light.disabled, .btn.btn-light-light:disabled {
  color: #F3F6F9;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-light-light.disabled i, .btn.btn-light-light:disabled i {
  color: #F3F6F9;
}

.btn.btn-light-light.disabled .svg-icon svg g [fill], .btn.btn-light-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-light-light.disabled .svg-icon svg:hover g [fill], .btn.btn-light-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-light.disabled.dropdown-toggle:after, .btn.btn-light-light:disabled.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-light.dropdown-toggle,
.show .btn.btn-light-light.btn-dropdown {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-light.dropdown-toggle i,
.show .btn.btn-light-light.btn-dropdown i {
  color: #80808F;
}

.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-light.btn-dropdown.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-transparent-light {
  color: #F3F6F9;
  background-color: rgba(243, 246, 249, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-light i {
  color: #F3F6F9;
}

.btn.btn-transparent-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-transparent-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-light.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-transparent-light:hover:not(.btn-text), .btn.btn-transparent-light:focus:not(.btn-text), .btn.btn-transparent-light.focus:not(.btn-text) {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-transparent-light:hover:not(.btn-text) i, .btn.btn-transparent-light:focus:not(.btn-text) i, .btn.btn-transparent-light.focus:not(.btn-text) i {
  color: #80808F;
}

.btn.btn-transparent-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-transparent-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-transparent-light.disabled, .btn.btn-transparent-light:disabled {
  color: #F3F6F9;
  background-color: rgba(243, 246, 249, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-light.disabled i, .btn.btn-transparent-light:disabled i {
  color: #F3F6F9;
}

.btn.btn-transparent-light.disabled .svg-icon svg g [fill], .btn.btn-transparent-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-transparent-light.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-light.disabled.dropdown-toggle:after, .btn.btn-transparent-light:disabled.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-light.dropdown-toggle,
.show .btn.btn-transparent-light.btn-dropdown {
  color: #80808F;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-light.dropdown-toggle i,
.show .btn.btn-transparent-light.btn-dropdown i {
  color: #80808F;
}

.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F;
}

.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-light.btn-dropdown.dropdown-toggle:after {
  color: #80808F;
}

.btn.btn-hover-transparent-light {
  color: #F3F6F9;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-light i {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-transparent-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-light.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light:hover:not(.btn-text), .btn.btn-hover-transparent-light:focus:not(.btn-text), .btn.btn-hover-transparent-light.focus:not(.btn-text) {
  color: #F3F6F9;
  background-color: rgba(243, 246, 249, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-light:hover:not(.btn-text) i, .btn.btn-hover-transparent-light:focus:not(.btn-text) i, .btn.btn-hover-transparent-light.focus:not(.btn-text) i {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-transparent-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light.disabled, .btn.btn-hover-transparent-light:disabled {
  color: #F3F6F9;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-light.disabled i, .btn.btn-hover-transparent-light:disabled i {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-transparent-light.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-light.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-light:disabled.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-light.dropdown-toggle,
.show .btn.btn-hover-transparent-light.btn-dropdown {
  color: #F3F6F9;
  background-color: rgba(243, 246, 249, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-light.dropdown-toggle i,
.show .btn.btn-hover-transparent-light.btn-dropdown i {
  color: #F3F6F9;
}

.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-light.btn-dropdown.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-light-light:hover:not(.btn-text), .btn.btn-hover-light-light:focus:not(.btn-text), .btn.btn-hover-light-light.focus:not(.btn-text) {
  color: #F3F6F9;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-hover-light-light:hover:not(.btn-text) i, .btn.btn-hover-light-light:focus:not(.btn-text) i, .btn.btn-hover-light-light.focus:not(.btn-text) i {
  color: #F3F6F9;
}

.btn.btn-hover-light-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-light-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-light.dropdown-toggle,
.show .btn.btn-hover-light-light.btn-dropdown {
  color: #F3F6F9;
  background-color: #F3F6F9;
  border-color: transparent;
}

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-light.dropdown-toggle i,
.show .btn.btn-hover-light-light.btn-dropdown i {
  color: #F3F6F9;
}

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-light.btn-dropdown.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-light-light.btn-clean {
  border: 0 !important;
}

.btn.btn-link-light {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #F3F6F9;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-light i {
  color: #F3F6F9;
}

.btn.btn-link-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-link-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-light.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-link-light:hover:not(.btn-text), .btn.btn-link-light:focus:not(.btn-text), .btn.btn-link-light.focus:not(.btn-text) {
  color: #E5EAEE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-light:hover:not(.btn-text) i, .btn.btn-link-light:focus:not(.btn-text) i, .btn.btn-link-light.focus:not(.btn-text) i {
  color: #E5EAEE;
}

.btn.btn-link-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-link-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-link-light.disabled, .btn.btn-link-light:disabled {
  color: #F3F6F9;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-light.disabled i, .btn.btn-link-light:disabled i {
  color: #F3F6F9;
}

.btn.btn-link-light.disabled .svg-icon svg g [fill], .btn.btn-link-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-link-light.disabled .svg-icon svg:hover g [fill], .btn.btn-link-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-light.disabled.dropdown-toggle:after, .btn.btn-link-light:disabled.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-light.dropdown-toggle,
.show .btn.btn-link-light.btn-dropdown {
  color: #E5EAEE;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-light.dropdown-toggle i,
.show .btn.btn-link-light.btn-dropdown i {
  color: #E5EAEE;
}

.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #E5EAEE;
}

.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-light.btn-dropdown.dropdown-toggle:after {
  color: #E5EAEE;
}

.btn.btn-hover-light:hover:not(.btn-text), .btn.btn-hover-light:focus:not(.btn-text), .btn.btn-hover-light.focus:not(.btn-text) {
  color: #80808F !important;
  background-color: #F3F6F9 !important;
  border-color: #F3F6F9 !important;
}

.btn.btn-hover-light:hover:not(.btn-text) i, .btn.btn-hover-light:focus:not(.btn-text) i, .btn.btn-hover-light.focus:not(.btn-text) i {
  color: #80808F !important;
}

.btn.btn-hover-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F !important;
}

.btn.btn-hover-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #80808F !important;
}

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light.dropdown-toggle,
.show .btn.btn-hover-light.btn-dropdown {
  color: #80808F !important;
  background-color: #F3F6F9 !important;
  border-color: #F3F6F9 !important;
}

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light.dropdown-toggle i,
.show .btn.btn-hover-light.btn-dropdown i {
  color: #80808F !important;
}

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #80808F !important;
}

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light.btn-dropdown.dropdown-toggle:after {
  color: #80808F !important;
}

.btn.btn-hover-light.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-light:hover:not(.btn-text), .btn.btn-hover-text-light:focus:not(.btn-text), .btn.btn-hover-text-light.focus:not(.btn-text) {
  color: #F3F6F9 !important;
}

.btn.btn-hover-text-light:hover:not(.btn-text) i, .btn.btn-hover-text-light:focus:not(.btn-text) i, .btn.btn-hover-text-light.focus:not(.btn-text) i {
  color: #F3F6F9 !important;
}

.btn.btn-hover-text-light:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-light:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-light.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9 !important;
}

.btn.btn-hover-text-light:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-light:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-light.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-light:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-light:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-light.focus:not(.btn-text).dropdown-toggle:after {
  color: #F3F6F9 !important;
}

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-light.dropdown-toggle,
.show .btn.btn-hover-text-light.btn-dropdown {
  color: #F3F6F9 !important;
}

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-light:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-light.dropdown-toggle i,
.show .btn.btn-hover-text-light.btn-dropdown i {
  color: #F3F6F9 !important;
}

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-light:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-light.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-light.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9 !important;
}

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-light:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-light.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-light.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-light:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-light:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-light.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-light.btn-dropdown.dropdown-toggle:after {
  color: #F3F6F9 !important;
}

.btn.btn-hover-bg-light:hover:not(.btn-text), .btn.btn-hover-bg-light:focus:not(.btn-text), .btn.btn-hover-bg-light.focus:not(.btn-text) {
  background-color: #F3F6F9 !important;
  border-color: #F3F6F9 !important;
}

.btn.btn-hover-bg-light:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-light:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-light.dropdown-toggle,
.show .btn.btn-hover-bg-light.btn-dropdown {
  background-color: #F3F6F9 !important;
  border-color: #F3F6F9 !important;
}

.btn.btn-text-light {
  color: #F3F6F9;
}

.btn.btn-text-light i {
  color: #F3F6F9;
}

.btn.btn-text-light .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-text-light .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-light.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-text-light.disabled, .btn.btn-text-light:disabled {
  color: #F3F6F9;
}

.btn.btn-text-light.disabled i, .btn.btn-text-light:disabled i {
  color: #F3F6F9;
}

.btn.btn-text-light.disabled .svg-icon svg g [fill], .btn.btn-text-light:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-text-light.disabled .svg-icon svg:hover g [fill], .btn.btn-text-light:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-light.disabled.dropdown-toggle:after, .btn.btn-text-light:disabled.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-bg-light {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-bg-light.disabled, .btn.btn-bg-light:disabled {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-light.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
}

.btn.btn-light.btn-shadow:hover:not(.btn-text), .btn.btn-light.btn-shadow:focus:not(.btn-text), .btn.btn-light.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-light.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-light.btn-shadow-hover:hover:not(.btn-text), .btn.btn-light.btn-shadow-hover:focus:not(.btn-text), .btn.btn-light.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-light.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-light.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-dark {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-dark i {
  color: #ffffff;
}

.btn.btn-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark:hover:not(.btn-text), .btn.btn-dark:focus:not(.btn-text), .btn.btn-dark.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #111111;
  border-color: #111111;
}

.btn.btn-dark:hover:not(.btn-text) i, .btn.btn-dark:focus:not(.btn-text) i, .btn.btn-dark.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark.disabled, .btn.btn-dark:disabled {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-dark.disabled i, .btn.btn-dark:disabled i {
  color: #ffffff;
}

.btn.btn-dark.disabled .svg-icon svg g [fill], .btn.btn-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark.disabled.dropdown-toggle:after, .btn.btn-dark:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.dropdown-toggle,
.show .btn.btn-dark.btn-dropdown {
  color: #ffffff;
  background-color: #111111;
  border-color: #111111;
}

.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-dark.dropdown-toggle i,
.show .btn.btn-dark.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-dark.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-dark {
  color: #212121;
  background-color: transparent;
  border-color: #212121;
}

.btn.btn-outline-dark i {
  color: #212121;
}

.btn.btn-outline-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-outline-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-outline-dark:hover:not(.btn-text), .btn.btn-outline-dark:focus:not(.btn-text), .btn.btn-outline-dark.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-outline-dark:hover:not(.btn-text) i, .btn.btn-outline-dark:focus:not(.btn-text) i, .btn.btn-outline-dark.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-dark.disabled, .btn.btn-outline-dark:disabled {
  color: #212121;
  background-color: transparent;
  border-color: #212121;
}

.btn.btn-outline-dark.disabled i, .btn.btn-outline-dark:disabled i {
  color: #212121;
}

.btn.btn-outline-dark.disabled .svg-icon svg g [fill], .btn.btn-outline-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-outline-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark.disabled.dropdown-toggle:after, .btn.btn-outline-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-dark.dropdown-toggle,
.show .btn.btn-outline-dark.btn-dropdown {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-dark.dropdown-toggle i,
.show .btn.btn-outline-dark.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-dark.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-dark {
  color: #212121;
  background-color: #D6D6E0;
  border-color: transparent;
}

.btn.btn-light-dark i {
  color: #212121;
}

.btn.btn-light-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-light-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-light-dark:hover:not(.btn-text), .btn.btn-light-dark:focus:not(.btn-text), .btn.btn-light-dark.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #212121;
  border-color: transparent;
}

.btn.btn-light-dark:hover:not(.btn-text) i, .btn.btn-light-dark:focus:not(.btn-text) i, .btn.btn-light-dark.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-dark.disabled, .btn.btn-light-dark:disabled {
  color: #212121;
  background-color: #D6D6E0;
  border-color: transparent;
}

.btn.btn-light-dark.disabled i, .btn.btn-light-dark:disabled i {
  color: #212121;
}

.btn.btn-light-dark.disabled .svg-icon svg g [fill], .btn.btn-light-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-light-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-light-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark.disabled.dropdown-toggle:after, .btn.btn-light-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-dark.dropdown-toggle,
.show .btn.btn-light-dark.btn-dropdown {
  color: #ffffff;
  background-color: #212121;
  border-color: transparent;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-dark.dropdown-toggle i,
.show .btn.btn-light-dark.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-dark.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-dark {
  color: #212121;
  background-color: rgba(33, 33, 33, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-dark i {
  color: #212121;
}

.btn.btn-transparent-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-transparent-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-transparent-dark:hover:not(.btn-text), .btn.btn-transparent-dark:focus:not(.btn-text), .btn.btn-transparent-dark.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #212121;
  border-color: transparent;
}

.btn.btn-transparent-dark:hover:not(.btn-text) i, .btn.btn-transparent-dark:focus:not(.btn-text) i, .btn.btn-transparent-dark.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-dark.disabled, .btn.btn-transparent-dark:disabled {
  color: #212121;
  background-color: rgba(33, 33, 33, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-dark.disabled i, .btn.btn-transparent-dark:disabled i {
  color: #212121;
}

.btn.btn-transparent-dark.disabled .svg-icon svg g [fill], .btn.btn-transparent-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-transparent-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark.disabled.dropdown-toggle:after, .btn.btn-transparent-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-dark.dropdown-toggle,
.show .btn.btn-transparent-dark.btn-dropdown {
  color: #ffffff;
  background-color: #212121;
  border-color: transparent;
}

.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-dark.dropdown-toggle i,
.show .btn.btn-transparent-dark.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-dark.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-dark {
  color: #212121;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-dark i {
  color: #212121;
}

.btn.btn-hover-transparent-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-transparent-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-transparent-dark:hover:not(.btn-text), .btn.btn-hover-transparent-dark:focus:not(.btn-text), .btn.btn-hover-transparent-dark.focus:not(.btn-text) {
  color: #212121;
  background-color: rgba(33, 33, 33, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-dark:hover:not(.btn-text) i, .btn.btn-hover-transparent-dark:focus:not(.btn-text) i, .btn.btn-hover-transparent-dark.focus:not(.btn-text) i {
  color: #212121;
}

.btn.btn-hover-transparent-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-transparent-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-transparent-dark.disabled, .btn.btn-hover-transparent-dark:disabled {
  color: #212121;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-dark.disabled i, .btn.btn-hover-transparent-dark:disabled i {
  color: #212121;
}

.btn.btn-hover-transparent-dark.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-transparent-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle,
.show .btn.btn-hover-transparent-dark.btn-dropdown {
  color: #212121;
  background-color: rgba(33, 33, 33, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle i,
.show .btn.btn-hover-transparent-dark.btn-dropdown i {
  color: #212121;
}

.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-dark.btn-dropdown.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-light-dark:hover:not(.btn-text), .btn.btn-hover-light-dark:focus:not(.btn-text), .btn.btn-hover-light-dark.focus:not(.btn-text) {
  color: #212121;
  background-color: #D6D6E0;
  border-color: transparent;
}

.btn.btn-hover-light-dark:hover:not(.btn-text) i, .btn.btn-hover-light-dark:focus:not(.btn-text) i, .btn.btn-hover-light-dark.focus:not(.btn-text) i {
  color: #212121;
}

.btn.btn-hover-light-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-light-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-dark.dropdown-toggle,
.show .btn.btn-hover-light-dark.btn-dropdown {
  color: #212121;
  background-color: #D6D6E0;
  border-color: transparent;
}

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-dark.dropdown-toggle i,
.show .btn.btn-hover-light-dark.btn-dropdown i {
  color: #212121;
}

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-dark.btn-dropdown.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-light-dark.btn-clean {
  border: 0 !important;
}

.btn.btn-link-dark {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #212121;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark i {
  color: #212121;
}

.btn.btn-link-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-link-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-link-dark:hover:not(.btn-text), .btn.btn-link-dark:focus:not(.btn-text), .btn.btn-link-dark.focus:not(.btn-text) {
  color: #111111;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark:hover:not(.btn-text) i, .btn.btn-link-dark:focus:not(.btn-text) i, .btn.btn-link-dark.focus:not(.btn-text) i {
  color: #111111;
}

.btn.btn-link-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #111111;
}

.btn.btn-link-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #111111;
}

.btn.btn-link-dark.disabled, .btn.btn-link-dark:disabled {
  color: #212121;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark.disabled i, .btn.btn-link-dark:disabled i {
  color: #212121;
}

.btn.btn-link-dark.disabled .svg-icon svg g [fill], .btn.btn-link-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-link-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-link-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark.disabled.dropdown-toggle:after, .btn.btn-link-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-dark.dropdown-toggle,
.show .btn.btn-link-dark.btn-dropdown {
  color: #111111;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-dark.dropdown-toggle i,
.show .btn.btn-link-dark.btn-dropdown i {
  color: #111111;
}

.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #111111;
}

.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-dark.btn-dropdown.dropdown-toggle:after {
  color: #111111;
}

.btn.btn-hover-dark:hover:not(.btn-text), .btn.btn-hover-dark:focus:not(.btn-text), .btn.btn-hover-dark.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
}

.btn.btn-hover-dark:hover:not(.btn-text) i, .btn.btn-hover-dark:focus:not(.btn-text) i, .btn.btn-hover-dark.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-dark.dropdown-toggle,
.show .btn.btn-hover-dark.btn-dropdown {
  color: #ffffff !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
}

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-dark.dropdown-toggle i,
.show .btn.btn-hover-dark.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-dark.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-dark.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-dark:hover:not(.btn-text), .btn.btn-hover-text-dark:focus:not(.btn-text), .btn.btn-hover-text-dark.focus:not(.btn-text) {
  color: #212121 !important;
}

.btn.btn-hover-text-dark:hover:not(.btn-text) i, .btn.btn-hover-text-dark:focus:not(.btn-text) i, .btn.btn-hover-text-dark.focus:not(.btn-text) i {
  color: #212121 !important;
}

.btn.btn-hover-text-dark:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121 !important;
}

.btn.btn-hover-text-dark:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-dark:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark.focus:not(.btn-text).dropdown-toggle:after {
  color: #212121 !important;
}

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark.dropdown-toggle,
.show .btn.btn-hover-text-dark.btn-dropdown {
  color: #212121 !important;
}

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-dark.dropdown-toggle i,
.show .btn.btn-hover-text-dark.btn-dropdown i {
  color: #212121 !important;
}

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-dark.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-dark.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121 !important;
}

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-dark.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-dark.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-dark:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-dark.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-dark.btn-dropdown.dropdown-toggle:after {
  color: #212121 !important;
}

.btn.btn-hover-bg-dark:hover:not(.btn-text), .btn.btn-hover-bg-dark:focus:not(.btn-text), .btn.btn-hover-bg-dark.focus:not(.btn-text) {
  background-color: #212121 !important;
  border-color: #212121 !important;
}

.btn.btn-hover-bg-dark:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-dark:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-dark.dropdown-toggle,
.show .btn.btn-hover-bg-dark.btn-dropdown {
  background-color: #212121 !important;
  border-color: #212121 !important;
}

.btn.btn-text-dark {
  color: #212121;
}

.btn.btn-text-dark i {
  color: #212121;
}

.btn.btn-text-dark .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-text-dark .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-dark.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-text-dark.disabled, .btn.btn-text-dark:disabled {
  color: #212121;
}

.btn.btn-text-dark.disabled i, .btn.btn-text-dark:disabled i {
  color: #212121;
}

.btn.btn-text-dark.disabled .svg-icon svg g [fill], .btn.btn-text-dark:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-text-dark.disabled .svg-icon svg:hover g [fill], .btn.btn-text-dark:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-dark.disabled.dropdown-toggle:after, .btn.btn-text-dark:disabled.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-bg-dark {
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-bg-dark.disabled, .btn.btn-bg-dark:disabled {
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-dark.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
}

.btn.btn-dark.btn-shadow:hover:not(.btn-text), .btn.btn-dark.btn-shadow:focus:not(.btn-text), .btn.btn-dark.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-dark.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-dark.btn-shadow-hover:hover:not(.btn-text), .btn.btn-dark.btn-shadow-hover:focus:not(.btn-text), .btn.btn-dark.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-dark.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-white {
  color: #464E5F;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-white i {
  color: #464E5F;
}

.btn.btn-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-white.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-white:hover:not(.btn-text), .btn.btn-white:focus:not(.btn-text), .btn.btn-white.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-white:hover:not(.btn-text) i, .btn.btn-white:focus:not(.btn-text) i, .btn.btn-white.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-white.disabled, .btn.btn-white:disabled {
  color: #464E5F;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-white.disabled i, .btn.btn-white:disabled i {
  color: #464E5F;
}

.btn.btn-white.disabled .svg-icon svg g [fill], .btn.btn-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-white.disabled .svg-icon svg:hover g [fill], .btn.btn-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-white.disabled.dropdown-toggle:after, .btn.btn-white:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.dropdown-toggle,
.show .btn.btn-white.btn-dropdown {
  color: #464E5F;
  background-color: #F3F6F9;
  border-color: #F3F6F9;
}

.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-white.dropdown-toggle i,
.show .btn.btn-white.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-white.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}

.btn.btn-outline-white i {
  color: #ffffff;
}

.btn.btn-outline-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-white:hover:not(.btn-text), .btn.btn-outline-white:focus:not(.btn-text), .btn.btn-outline-white.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-outline-white:hover:not(.btn-text) i, .btn.btn-outline-white:focus:not(.btn-text) i, .btn.btn-outline-white.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-outline-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-white.disabled, .btn.btn-outline-white:disabled {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}

.btn.btn-outline-white.disabled i, .btn.btn-outline-white:disabled i {
  color: #ffffff;
}

.btn.btn-outline-white.disabled .svg-icon svg g [fill], .btn.btn-outline-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-white.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-white.disabled.dropdown-toggle:after, .btn.btn-outline-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-white.dropdown-toggle,
.show .btn.btn-outline-white.btn-dropdown {
  color: #464E5F;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-white.dropdown-toggle i,
.show .btn.btn-outline-white.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-white.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-white {
  color: #ffffff;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-light-white i {
  color: #ffffff;
}

.btn.btn-light-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-white:hover:not(.btn-text), .btn.btn-light-white:focus:not(.btn-text), .btn.btn-light-white.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-light-white:hover:not(.btn-text) i, .btn.btn-light-white:focus:not(.btn-text) i, .btn.btn-light-white.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-light-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-white.disabled, .btn.btn-light-white:disabled {
  color: #ffffff;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-light-white.disabled i, .btn.btn-light-white:disabled i {
  color: #ffffff;
}

.btn.btn-light-white.disabled .svg-icon svg g [fill], .btn.btn-light-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-white.disabled .svg-icon svg:hover g [fill], .btn.btn-light-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-white.disabled.dropdown-toggle:after, .btn.btn-light-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-white.dropdown-toggle,
.show .btn.btn-light-white.btn-dropdown {
  color: #464E5F;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-white.dropdown-toggle i,
.show .btn.btn-light-white.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-white.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-white {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-white i {
  color: #ffffff;
}

.btn.btn-transparent-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-white:hover:not(.btn-text), .btn.btn-transparent-white:focus:not(.btn-text), .btn.btn-transparent-white.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-transparent-white:hover:not(.btn-text) i, .btn.btn-transparent-white:focus:not(.btn-text) i, .btn.btn-transparent-white.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-transparent-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-white.disabled, .btn.btn-transparent-white:disabled {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-white.disabled i, .btn.btn-transparent-white:disabled i {
  color: #ffffff;
}

.btn.btn-transparent-white.disabled .svg-icon svg g [fill], .btn.btn-transparent-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-white.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-white.disabled.dropdown-toggle:after, .btn.btn-transparent-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-white.dropdown-toggle,
.show .btn.btn-transparent-white.btn-dropdown {
  color: #464E5F;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-white.dropdown-toggle i,
.show .btn.btn-transparent-white.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-white.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-transparent-white {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-white i {
  color: #ffffff;
}

.btn.btn-hover-transparent-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-transparent-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-white:hover:not(.btn-text), .btn.btn-hover-transparent-white:focus:not(.btn-text), .btn.btn-hover-transparent-white.focus:not(.btn-text) {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-white:hover:not(.btn-text) i, .btn.btn-hover-transparent-white:focus:not(.btn-text) i, .btn.btn-hover-transparent-white.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-hover-transparent-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-transparent-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-white.disabled, .btn.btn-hover-transparent-white:disabled {
  color: #ffffff;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-white.disabled i, .btn.btn-hover-transparent-white:disabled i {
  color: #ffffff;
}

.btn.btn-hover-transparent-white.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-transparent-white.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-white.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-white.dropdown-toggle,
.show .btn.btn-hover-transparent-white.btn-dropdown {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-white.dropdown-toggle i,
.show .btn.btn-hover-transparent-white.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-white.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-light-white:hover:not(.btn-text), .btn.btn-hover-light-white:focus:not(.btn-text), .btn.btn-hover-light-white.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-hover-light-white:hover:not(.btn-text) i, .btn.btn-hover-light-white:focus:not(.btn-text) i, .btn.btn-hover-light-white.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-hover-light-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-light-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-white.dropdown-toggle,
.show .btn.btn-hover-light-white.btn-dropdown {
  color: #ffffff;
  background-color: #ffffff;
  border-color: transparent;
}

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-white.dropdown-toggle i,
.show .btn.btn-hover-light-white.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-white.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-light-white.btn-clean {
  border: 0 !important;
}

.btn.btn-link-white {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #ffffff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-white i {
  color: #ffffff;
}

.btn.btn-link-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-link-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-link-white:hover:not(.btn-text), .btn.btn-link-white:focus:not(.btn-text), .btn.btn-link-white.focus:not(.btn-text) {
  color: #F3F6F9;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-white:hover:not(.btn-text) i, .btn.btn-link-white:focus:not(.btn-text) i, .btn.btn-link-white.focus:not(.btn-text) i {
  color: #F3F6F9;
}

.btn.btn-link-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-link-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-link-white.disabled, .btn.btn-link-white:disabled {
  color: #ffffff;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-white.disabled i, .btn.btn-link-white:disabled i {
  color: #ffffff;
}

.btn.btn-link-white.disabled .svg-icon svg g [fill], .btn.btn-link-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-link-white.disabled .svg-icon svg:hover g [fill], .btn.btn-link-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-white.disabled.dropdown-toggle:after, .btn.btn-link-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-white.dropdown-toggle,
.show .btn.btn-link-white.btn-dropdown {
  color: #F3F6F9;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-white.dropdown-toggle i,
.show .btn.btn-link-white.btn-dropdown i {
  color: #F3F6F9;
}

.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #F3F6F9;
}

.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-white.btn-dropdown.dropdown-toggle:after {
  color: #F3F6F9;
}

.btn.btn-hover-white:hover:not(.btn-text), .btn.btn-hover-white:focus:not(.btn-text), .btn.btn-hover-white.focus:not(.btn-text) {
  color: #464E5F !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.btn.btn-hover-white:hover:not(.btn-text) i, .btn.btn-hover-white:focus:not(.btn-text) i, .btn.btn-hover-white.focus:not(.btn-text) i {
  color: #464E5F !important;
}

.btn.btn-hover-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-white.dropdown-toggle,
.show .btn.btn-hover-white.btn-dropdown {
  color: #464E5F !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-white.dropdown-toggle i,
.show .btn.btn-hover-white.btn-dropdown i {
  color: #464E5F !important;
}

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-white.btn-dropdown.dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-white.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-white:hover:not(.btn-text), .btn.btn-hover-text-white:focus:not(.btn-text), .btn.btn-hover-text-white.focus:not(.btn-text) {
  color: #ffffff !important;
}

.btn.btn-hover-text-white:hover:not(.btn-text) i, .btn.btn-hover-text-white:focus:not(.btn-text) i, .btn.btn-hover-text-white.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-text-white:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-white:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-white.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-text-white:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-white:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-white.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-white:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-white:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-white.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-white.dropdown-toggle,
.show .btn.btn-hover-text-white.btn-dropdown {
  color: #ffffff !important;
}

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-white:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-white.dropdown-toggle i,
.show .btn.btn-hover-text-white.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-white:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-white.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-white.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-white:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-white.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-white.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-white:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-white:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-white.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-white.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-bg-white:hover:not(.btn-text), .btn.btn-hover-bg-white:focus:not(.btn-text), .btn.btn-hover-bg-white.focus:not(.btn-text) {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.btn.btn-hover-bg-white:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-white:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-white.dropdown-toggle,
.show .btn.btn-hover-bg-white.btn-dropdown {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.btn.btn-text-white {
  color: #ffffff;
}

.btn.btn-text-white i {
  color: #ffffff;
}

.btn.btn-text-white .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-text-white .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-text-white.disabled, .btn.btn-text-white:disabled {
  color: #ffffff;
}

.btn.btn-text-white.disabled i, .btn.btn-text-white:disabled i {
  color: #ffffff;
}

.btn.btn-text-white.disabled .svg-icon svg g [fill], .btn.btn-text-white:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-text-white.disabled .svg-icon svg:hover g [fill], .btn.btn-text-white:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-white.disabled.dropdown-toggle:after, .btn.btn-text-white:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-white {
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-bg-white.disabled, .btn.btn-bg-white:disabled {
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-white.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.25) !important;
}

.btn.btn-white.btn-shadow:hover:not(.btn-text), .btn.btn-white.btn-shadow:focus:not(.btn-text), .btn.btn-white.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-white.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-white.btn-shadow-hover:hover:not(.btn-text), .btn.btn-white.btn-shadow-hover:focus:not(.btn-text), .btn.btn-white.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-white.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-white.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(33, 33, 33, 0.3) !important;
}

.btn.btn-dark-75 {
  color: #ffffff;
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-dark-75 i {
  color: #ffffff;
}

.btn.btn-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark-75.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark-75:hover:not(.btn-text), .btn.btn-dark-75:focus:not(.btn-text), .btn.btn-dark-75.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-dark-75:hover:not(.btn-text) i, .btn.btn-dark-75:focus:not(.btn-text) i, .btn.btn-dark-75.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark-75.disabled, .btn.btn-dark-75:disabled {
  color: #ffffff;
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-dark-75.disabled i, .btn.btn-dark-75:disabled i {
  color: #ffffff;
}

.btn.btn-dark-75.disabled .svg-icon svg g [fill], .btn.btn-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark-75.disabled.dropdown-toggle:after, .btn.btn-dark-75:disabled.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark-75.dropdown-toggle,
.show .btn.btn-dark-75.btn-dropdown {
  color: #ffffff;
  background-color: #212121;
  border-color: #212121;
}

.btn.btn-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-dark-75.dropdown-toggle i,
.show .btn.btn-dark-75.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-dark-75 {
  color: #464E5F;
  background-color: transparent;
  border-color: #464E5F;
}

.btn.btn-outline-dark-75 i {
  color: #464E5F;
}

.btn.btn-outline-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-dark-75:hover:not(.btn-text), .btn.btn-outline-dark-75:focus:not(.btn-text), .btn.btn-outline-dark-75.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-outline-dark-75:hover:not(.btn-text) i, .btn.btn-outline-dark-75:focus:not(.btn-text) i, .btn.btn-outline-dark-75.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-dark-75.disabled, .btn.btn-outline-dark-75:disabled {
  color: #464E5F;
  background-color: transparent;
  border-color: #464E5F;
}

.btn.btn-outline-dark-75.disabled i, .btn.btn-outline-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-outline-dark-75.disabled .svg-icon svg g [fill], .btn.btn-outline-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-outline-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-outline-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark-75.disabled.dropdown-toggle:after, .btn.btn-outline-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-outline-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-outline-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-dark-75.dropdown-toggle,
.show .btn.btn-outline-dark-75.btn-dropdown {
  color: #ffffff;
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-outline-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-outline-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-dark-75.dropdown-toggle i,
.show .btn.btn-outline-dark-75.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-outline-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-outline-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-outline-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-outline-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-outline-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-dark-75 {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-light-dark-75 i {
  color: #464E5F;
}

.btn.btn-light-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-dark-75:hover:not(.btn-text), .btn.btn-light-dark-75:focus:not(.btn-text), .btn.btn-light-dark-75.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #464E5F;
  border-color: transparent;
}

.btn.btn-light-dark-75:hover:not(.btn-text) i, .btn.btn-light-dark-75:focus:not(.btn-text) i, .btn.btn-light-dark-75.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-light-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-dark-75.disabled, .btn.btn-light-dark-75:disabled {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-light-dark-75.disabled i, .btn.btn-light-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-light-dark-75.disabled .svg-icon svg g [fill], .btn.btn-light-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-light-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-light-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark-75.disabled.dropdown-toggle:after, .btn.btn-light-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-light-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-light-dark-75.dropdown-toggle,
.show .btn.btn-light-dark-75.btn-dropdown {
  color: #ffffff;
  background-color: #464E5F;
  border-color: transparent;
}

.btn.btn-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-light-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-light-dark-75.dropdown-toggle i,
.show .btn.btn-light-dark-75.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-light-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-light-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-light-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-light-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-dark-75 {
  color: #464E5F;
  background-color: rgba(70, 78, 95, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-dark-75 i {
  color: #464E5F;
}

.btn.btn-transparent-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-dark-75:hover:not(.btn-text), .btn.btn-transparent-dark-75:focus:not(.btn-text), .btn.btn-transparent-dark-75.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #464E5F;
  border-color: transparent;
}

.btn.btn-transparent-dark-75:hover:not(.btn-text) i, .btn.btn-transparent-dark-75:focus:not(.btn-text) i, .btn.btn-transparent-dark-75.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-transparent-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-transparent-dark-75.disabled, .btn.btn-transparent-dark-75:disabled {
  color: #464E5F;
  background-color: rgba(70, 78, 95, 0.1);
  border-color: transparent;
}

.btn.btn-transparent-dark-75.disabled i, .btn.btn-transparent-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-transparent-dark-75.disabled .svg-icon svg g [fill], .btn.btn-transparent-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-transparent-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-transparent-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark-75.disabled.dropdown-toggle:after, .btn.btn-transparent-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-transparent-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-transparent-dark-75.dropdown-toggle,
.show .btn.btn-transparent-dark-75.btn-dropdown {
  color: #ffffff;
  background-color: #464E5F;
  border-color: transparent;
}

.btn.btn-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-transparent-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-transparent-dark-75.dropdown-toggle i,
.show .btn.btn-transparent-dark-75.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-transparent-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-transparent-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-transparent-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-transparent-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-transparent-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-transparent-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-transparent-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-transparent-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-transparent-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-hover-transparent-dark-75 {
  color: #464E5F;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-dark-75 i {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-transparent-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75:hover:not(.btn-text), .btn.btn-hover-transparent-dark-75:focus:not(.btn-text), .btn.btn-hover-transparent-dark-75.focus:not(.btn-text) {
  color: #464E5F;
  background-color: rgba(70, 78, 95, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-dark-75:hover:not(.btn-text) i, .btn.btn-hover-transparent-dark-75:focus:not(.btn-text) i, .btn.btn-hover-transparent-dark-75.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-transparent-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75.disabled, .btn.btn-hover-transparent-dark-75:disabled {
  color: #464E5F;
  background-color: transparent;
  border-color: transparent;
}

.btn.btn-hover-transparent-dark-75.disabled i, .btn.btn-hover-transparent-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75.disabled .svg-icon svg g [fill], .btn.btn-hover-transparent-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-transparent-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark-75.disabled.dropdown-toggle:after, .btn.btn-hover-transparent-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-transparent-dark-75.dropdown-toggle,
.show .btn.btn-hover-transparent-dark-75.btn-dropdown {
  color: #464E5F;
  background-color: rgba(70, 78, 95, 0.1);
  border-color: transparent;
}

.btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-transparent-dark-75.dropdown-toggle i,
.show .btn.btn-hover-transparent-dark-75.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-transparent-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-transparent-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-transparent-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-transparent-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-transparent-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-transparent-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-transparent-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-light-dark-75:hover:not(.btn-text), .btn.btn-hover-light-dark-75:focus:not(.btn-text), .btn.btn-hover-light-dark-75.focus:not(.btn-text) {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-hover-light-dark-75:hover:not(.btn-text) i, .btn.btn-hover-light-dark-75:focus:not(.btn-text) i, .btn.btn-hover-light-dark-75.focus:not(.btn-text) i {
  color: #464E5F;
}

.btn.btn-hover-light-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-light-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-light-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-light-dark-75.dropdown-toggle,
.show .btn.btn-hover-light-dark-75.btn-dropdown {
  color: #464E5F;
  background-color: #E5EAEE;
  border-color: transparent;
}

.btn.btn-hover-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-light-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-light-dark-75.dropdown-toggle i,
.show .btn.btn-hover-light-dark-75.btn-dropdown i {
  color: #464E5F;
}

.btn.btn-hover-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-light-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-light-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-light-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-hover-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-light-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-light-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-light-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-light-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-light-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-light-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-light-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-hover-light-dark-75.btn-clean {
  border: 0 !important;
}

.btn.btn-link-dark-75 {
  border: 0;
  padding: 0 !important;
  border-radius: 0 !important;
  color: #464E5F;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark-75 i {
  color: #464E5F;
}

.btn.btn-link-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-link-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-link-dark-75:hover:not(.btn-text), .btn.btn-link-dark-75:focus:not(.btn-text), .btn.btn-link-dark-75.focus:not(.btn-text) {
  color: #212121;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark-75:hover:not(.btn-text) i, .btn.btn-link-dark-75:focus:not(.btn-text) i, .btn.btn-link-dark-75.focus:not(.btn-text) i {
  color: #212121;
}

.btn.btn-link-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-link-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #212121;
}

.btn.btn-link-dark-75.disabled, .btn.btn-link-dark-75:disabled {
  color: #464E5F;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark-75.disabled i, .btn.btn-link-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-link-dark-75.disabled .svg-icon svg g [fill], .btn.btn-link-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-link-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-link-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark-75.disabled.dropdown-toggle:after, .btn.btn-link-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-link-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-link-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-link-dark-75.dropdown-toggle,
.show .btn.btn-link-dark-75.btn-dropdown {
  color: #212121;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn.btn-link-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-link-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-link-dark-75.dropdown-toggle i,
.show .btn.btn-link-dark-75.btn-dropdown i {
  color: #212121;
}

.btn.btn-link-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-link-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-link-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-link-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #212121;
}

.btn.btn-link-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-link-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-link-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-link-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-link-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-link-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-link-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-link-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #212121;
}

.btn.btn-hover-dark-75:hover:not(.btn-text), .btn.btn-hover-dark-75:focus:not(.btn-text), .btn.btn-hover-dark-75.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #464E5F !important;
  border-color: #464E5F !important;
}

.btn.btn-hover-dark-75:hover:not(.btn-text) i, .btn.btn-hover-dark-75:focus:not(.btn-text) i, .btn.btn-hover-dark-75.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.btn.btn-hover-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-dark-75.dropdown-toggle,
.show .btn.btn-hover-dark-75.btn-dropdown {
  color: #ffffff !important;
  background-color: #464E5F !important;
  border-color: #464E5F !important;
}

.btn.btn-hover-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-dark-75.dropdown-toggle i,
.show .btn.btn-hover-dark-75.btn-dropdown i {
  color: #ffffff !important;
}

.btn.btn-hover-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff !important;
}

.btn.btn-hover-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #ffffff !important;
}

.btn.btn-hover-dark-75.btn-clean {
  border: 0 !important;
}

.btn.btn-hover-text-dark-75:hover:not(.btn-text), .btn.btn-hover-text-dark-75:focus:not(.btn-text), .btn.btn-hover-text-dark-75.focus:not(.btn-text) {
  color: #464E5F !important;
}

.btn.btn-hover-text-dark-75:hover:not(.btn-text) i, .btn.btn-hover-text-dark-75:focus:not(.btn-text) i, .btn.btn-hover-text-dark-75.focus:not(.btn-text) i {
  color: #464E5F !important;
}

.btn.btn-hover-text-dark-75:hover:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark-75:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark-75.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-text-dark-75:hover:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark-75:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark-75.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-dark-75:hover:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark-75:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark-75.focus:not(.btn-text).dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-text-dark-75.dropdown-toggle,
.show .btn.btn-hover-text-dark-75.btn-dropdown {
  color: #464E5F !important;
}

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) i, .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active i,
.show > .btn.btn-hover-text-dark-75.dropdown-toggle i,
.show .btn.btn-hover-text-dark-75.btn-dropdown i {
  color: #464E5F !important;
}

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show > .btn.btn-hover-text-dark-75.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-dark-75.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F !important;
}

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show > .btn.btn-hover-text-dark-75.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-dark-75.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-hover-text-dark-75:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .btn.btn-hover-text-dark-75:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-hover-text-dark-75.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-dark-75.btn-dropdown.dropdown-toggle:after {
  color: #464E5F !important;
}

.btn.btn-hover-bg-dark-75:hover:not(.btn-text), .btn.btn-hover-bg-dark-75:focus:not(.btn-text), .btn.btn-hover-bg-dark-75.focus:not(.btn-text) {
  background-color: #464E5F !important;
  border-color: #464E5F !important;
}

.btn.btn-hover-bg-dark-75:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-hover-bg-dark-75:not(:disabled):not(.disabled).active,
.show > .btn.btn-hover-bg-dark-75.dropdown-toggle,
.show .btn.btn-hover-bg-dark-75.btn-dropdown {
  background-color: #464E5F !important;
  border-color: #464E5F !important;
}

.btn.btn-text-dark-75 {
  color: #464E5F;
}

.btn.btn-text-dark-75 i {
  color: #464E5F;
}

.btn.btn-text-dark-75 .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-text-dark-75 .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-dark-75.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-text-dark-75.disabled, .btn.btn-text-dark-75:disabled {
  color: #464E5F;
}

.btn.btn-text-dark-75.disabled i, .btn.btn-text-dark-75:disabled i {
  color: #464E5F;
}

.btn.btn-text-dark-75.disabled .svg-icon svg g [fill], .btn.btn-text-dark-75:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #464E5F;
}

.btn.btn-text-dark-75.disabled .svg-icon svg:hover g [fill], .btn.btn-text-dark-75:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-text-dark-75.disabled.dropdown-toggle:after, .btn.btn-text-dark-75:disabled.dropdown-toggle:after {
  color: #464E5F;
}

.btn.btn-bg-dark-75 {
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-bg-dark-75.disabled, .btn.btn-bg-dark-75:disabled {
  background-color: #464E5F;
  border-color: #464E5F;
}

.btn.btn-dark-75.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.25) !important;
}

.btn.btn-dark-75.btn-shadow:hover:not(.btn-text), .btn.btn-dark-75.btn-shadow:focus:not(.btn-text), .btn.btn-dark-75.btn-shadow.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
}

.btn.btn-dark-75.btn-shadow:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark-75.btn-shadow:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark-75.btn-shadow.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
}

.btn.btn-dark-75.btn-shadow-hover:hover:not(.btn-text), .btn.btn-dark-75.btn-shadow-hover:focus:not(.btn-text), .btn.btn-dark-75.btn-shadow-hover.focus:not(.btn-text) {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
}

.btn.btn-dark-75.btn-shadow-hover:not(:disabled):not(.disabled):active:not(.btn-text), .btn.btn-dark-75.btn-shadow-hover:not(:disabled):not(.disabled).active,
.show > .btn.btn-dark-75.btn-shadow-hover.dropdown-toggle {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
  box-shadow: 0px 9px 16px 0px rgba(70, 78, 95, 0.3) !important;
}

// line height

.line-height-0 {
  line-height: 0;
}

.line-height-xs {
  line-height: 0.75;
}

.line-height-sm {
  line-height: 1;
}

.line-height-md {
  line-height: 1.25;
}

.line-height-lg {
  line-height: 1.5;
}

.line-height-xl {
  line-height: 1.75;
}

// Input

.input-icon {
  position: relative;
}

.input-icon span {
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: calc(1.5em + 1.3rem + 2px);
}

.input-icon span i {
  line-height: 0;
  color: #80808F;
}

.input-icon .form-control {
  padding-left: calc(1.5em + 1.3rem + 2px);
}

// Icon

.icon-xs {
  font-size: 0.65rem !important;
}

.icon-sm {
  font-size: 0.75rem !important;
}

.icon-md {
  font-size: 1.25rem !important;
}

.icon-lg {
  font-size: 1.5rem !important;
}

.icon-xl {
  font-size: 1.75rem !important;
}

.icon-xxl {
  font-size: 2.25rem !important;
}

.icon-1x {
  font-size: 1rem !important;
}

.icon-2x {
  font-size: 2rem !important;
}

.icon-3x {
  font-size: 3rem !important;
}

.icon-4x {
  font-size: 4rem !important;
}

.icon-5x {
  font-size: 5rem !important;
}

.icon-6x {
  font-size: 6rem !important;
}

.icon-7x {
  font-size: 7rem !important;
}

.icon-8x {
  font-size: 8rem !important;
}

.icon-9x {
  font-size: 9rem !important;
}

.icon-10x {
  font-size: 10rem !important;
}

// drag and drop file upload
.warehouseFileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.warehouseFileDiv {
  width: 100%;
  height: 160px;
  border: 2px dashed #ced4da;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  color: #6c757d;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.warehouseFileDiv.highlightDragAndDrop {
  border: 2px dashed #a1a3a9;
}

.print-content {
  display: none;
}
@media print {
  @page {
    // Hiding header and footer (stuff like url etc.)
    margin-top: 0;
    margin-bottom: 0;
  }
  .non-print-content {
    display: none;
  }
  .print-content {
    display: block;
  }
}