.button-link {
  color: black;
  font-weight: 600 !important;
  border: None;
  cursor: pointer;
  display: inline;
  background: transparent;
}

.button-link:hover,
.button-link:focus{
  text-decoration: underline;
}

.table-responsive {
  overflow-x: auto !important;
}

.button-splash-spinner {
  animation: rotate 2s linear infinite;
  width: 1.25rem;
  height: 1.25rem;
}

.button-splash-spinner .path {
  stroke: white;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.button-splash-spinner-dark .path {
  stroke: darkgray !important;
}


.calculation-details-header {
  background: white;
  border-radius: 10px;
  padding: 20px;
}

.calculation-details-header-hover:hover {
  cursor: pointer;
  background: #fcfcfc !important;
}

.calculation-details {
  border-radius: 10px
}

.cd-show {
}

.cd-collapse:not(.cd-show) {
  display: none !important;
}

.input-number-arrows-low-pr {
  padding-right: 2px;
}

.row-hover:hover {
  cursor: pointer;
  background: #eeeeee !important;
}

.pointer-all * {
  cursor: pointer !important;
}

.text-hover-dark:hover {
  color: black;
  opacity: 0.6 !important;
}

.material-row {
  cursor: pointer !important;
}

.material-row:hover {
  background: #f7f8fa
}

.text-hover-black:hover{
  color: black !important;
  font-weight: bolder;
}

select option:disabled{
  color: lightgray !important;
}

.custom-switch-lg .custom-control-label::before{
  width: 3.5rem !important;
  height: 1.5rem !important;
}

.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(2rem);
}

.custom-switch-lg .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px)
}

.customBackdrop {
  z-index: 940;
}

.extendedModalDialog {
  @include kt-tablet-and-mobile {
    max-width: 97vw !important;
  }
}

@include media-breakpoint-up(xl) {
  #notes-popover {
    width: 25vw;
  }
}

@include media-breakpoint-between(lg, xl) {
  #notes-popover {
    width: 30vw;
  }
}

@include media-breakpoint-between(md, lg) {
  #notes-popover {
    width: 40vw;
  }
}

@include media-breakpoint-between(sm, md) {
  #notes-popover {
    width: 50vw;
  }
}

@include media-breakpoint-between(xs, sm) {
  #notes-popover {
    width: 65vw;
  }
}

@include media-breakpoint-down(xs) {
  #notes-popover {
    width: 95vw;
  }
}

.pos-relatedOrdersButton {
  position: relative;
  left: -23px;
  top: 3px;
  background: #f5f5f5 !important;
  color: #a0a0a0 !important;
  transition: all 0.2s;
}

.pos-relatedOrdersButton.relatedOrderRemove:hover {
  background: red !important;
  color: white !important;
  cursor: pointer;
}

.pos-relatedOrdersButton.relatedOrderAdd:hover {
  background: $success !important;
  color: white !important;
  cursor: pointer;
}

.select-default > div {
  border-color: #e2e5ec !important;
}

.select-default.border-radius-left-0 > div {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select-default.is-invalid > div {
  border-color: red !important;
}

.hide-element {
  opacity: 0.6 !important;
  pointer-events: none !important;
}

.checkbox-dark {
  >input ~ span {
    border: 1px solid #a6aab7;
  }
  >input:checked ~ span {
    border: 1px solid #a6aab7;
  }
  span:after {
    border: solid #797c86;
  }
}

.nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link:hover > div > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand.nav.nav-tabs .nav-link.active > div > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link:hover > div > i, .nav-tabs.nav-tabs-line.nav-tabs-line-brand a.nav-link.active > div > i {
  color: #1a98ff;
}

.bg-rawbids {
  background-color: #131315 !important;;
}

.table-small-padding td {
  padding: 0.4rem !important;
  border-top: none !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.hide-arrows {
  -moz-appearance: textfield !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}