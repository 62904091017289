//
// Custom Example 2 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

/* body,
.progress {
  background: #050507 !important; // ganz dunkel
}

.kt-header,
.kt-subheader,
.kt-footer,
.kt-aside-menu,
.kt-portlet,
.tab-content,
.kt-wizard-v2__wrapper,
.kt-wizard-v2__nav-item {
  background-color: #17171f !important; // mitteldunkel
  border-bottom: 1px solid black !important;
}
.kt-widget__title h1,
h2,
h3,
span,
h4,
h5,
p,
a,
.kt-wizard-v2__nav-label-title,
.board_title,
.kt-font-dark,
.alert-text {
  color: #ced4eb !important; // text
}

select,
input,
table,
.droppable_fixed,
.modal-content,
.kt-datatable__row,
.alert-light {
  background: #262835 !important; // wenig dunkel
  border: 0px solid black !important;
  color: #9fa7be !important;
}

.calculation_search,
.input-group-text,
.table-hover:hover,
.modal-content input,
.modal-content select,
.kt-notes__content,
.kt-notes__content:after,
.form-control,
.react-datepicker-wrapper,
.btn-secondary,
.btn-light {
  background: #313444 !important; // ganz wenig dunkel
  border: 0px solid black !important;
  color: #9fa7be !important;
}

.kt-wizard-v2__aside {
  border-right: 1px solid #050507 !important;
}

.kt-wizard-v2__content,
.kt-portlet__head {
  border-bottom: 1px solid #050507 !important;
}

.kt-widget__bottom {
  border-top: 1px solid #050507 !important;
}

.kt-timeline-v2__item-cricle {
  border: 0.89rem solid #262835 !important; // wenig dunkel
}
 */
