//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.my-custom-style {
  padding: 1rem 0.5rem;
  color: kt-brand-color();

  .my-custom-element {
    margin-left: 1rem;
  }
}

@include kt-tablet-and-mobile {
  .my-custom-style {
    padding: 0.5rem 0.25rem;

    .my-custom-element {
      margin-left: 0.5rem;
    }
  }
}

.icon-btn {
  border: none;
  width: 2rem;
}

.accordion.accordion-toggle-plus .card .card-header .card-title {
  color: rgb(100, 108, 154) !important;
}
.pointer {
  cursor: pointer !important;
}

.kt-widget4 .kt-widget4__item .kt-widget4__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 1.25rem;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.kt-widget4__username {
  font-weight: 500;
  font-size: 1rem;
  color: #595d6e;

  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.kt-widget4__text {
  font-size: 1rem;
  margin: 0;
  color: #74788d;
}

.bg-red {
  background-color: red;
}

.pos-selectEmployee {
  position: absolute;
  left: 45px;
  background: #f5f5f5 !important;
  color: #a0a0a0 !important;
  transition: all 0.2s;
}

.pos-selectEmployee:hover {
  background: red !important;
  color: white !important;
  cursor: pointer;
}

.turnover_canvas {
  width: 100px;
}

.selectuser {
  opacity: 0.4;
  transition:0.3s;
}

.selectuser:hover {
  opacity: 1;
  cursor: pointer;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.country-icon {
  width: 18px;
  height: 18px;
}

.country-icon2 {
  width: 12px;
  height: 12px;
}

.multi-select .arrow {
  margin: auto;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  width: 20px;
  /*margin: 0 5px;*/
  display: inline-block;
  cursor: pointer;
  /* float: right; */
}

.multi-select .options.show {
  display: block;
}

.multi-select .options {
  height: 200px;
  z-index: 10001;
  overflow-x: auto;
  background-color: #fff;
  position: absolute;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  /* width: inherit; */
  padding: 0;
  display: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.options li {
  display: block;
  font-size: 16px;
}

.options li .option-list {
  cursor: default;
  margin-bottom: 2px;
  text-transform: capitalize;
  /*border-bottom: solid 2px #fff;*/
  padding: 2px 5px;
}

.multi-select .bg-color-blue {
  background-color: cornflowerblue;
  color: #fff;
}

.multi-select .selected-options {
  vertical-align: middle;
  height: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 0px 5px 10px;
  width: inherit;
  /*padding: 0 0 0 10px;*/
}

.multi-select .selected-options .selected-options-badges-list {
  font-weight: 400;
  margin: 0;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 20px);
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  /* float: left; */
  /*width: calc(100% - 30px);*/
  /*padding: 2px 0;*/
  /*line-height: 1.4;*/
}

.multi-select .selected-options .selected-options-badges:after {
  content: "×";
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
}

.multi-select {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  outline: none;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  /*height: 28px;*/
}

.multi-select .selected-options-badges {
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 2px;
  padding: 0 5px;
  margin: 0 5px 0 0;
  border: solid 1px #ddd;
  display: inline-block;
}

.bg-add {
  background-color: #f7f8fa;
  color: rgb(98, 98, 98);
  cursor: pointer;
}

.bg-add:hover {
  transition: 0.2s;
  background-color: #f0f0f0;
}

.dzstyle {
  flex: 1;
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  padding: "20px";
  border-width: 2;
  border-radius: 2;
  border-color: "#eeeeee";
  border-style: "dashed";
  background-color: "#fafafa";
  color: "#bdbdbd";
  outline: "none";
  transition: "border .24s ease-in-out";
}

.uploadcare--widget__button_type_open {
  background-color: #fafafa;
  color: #333333;
  display: block !important;
}

.uploadcare--widget__text {
  display: none !important;
}

.align-left {
  text-align: left !important;
}

.imgpreview {
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: #fafafa;
  display: inline-block !important;
  border-radius: 15px;
  vertical-align: top;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.inline-block {
  display: inline-block !important;
}

.pos-deleteImage {
  position: relative;
  left: 88px;
  top: -5px;
  background: #f5f5f5 !important;
  color: #a0a0a0 !important;
  transition: all 0.2s;
}

.pos-deleteImage:hover {
  background: red !important;
  color: white !important;
  cursor: pointer;
}

.packaging_img {
  object-fit: cover !important;
  height: 200px;
}

.disabled-i {
  color: lightgray;
}

.disabled-a {
  background-color: white;
  cursor: not-allowed;
}

.inline-flex {
  display: inline-flex !important;
}

.droppable_fixed {
  overflow-y: scroll;
  height: 80vh;
  position: relative;
  border-radius: 10px;
}

.rawmaterial_title {
  color: #50566a;
  font-weight: 500;
  font-size: 1.1rem;
}

.rawmaterial_subtitle {
  color: #959cb6;
  margin-bottom: 5px !important;
}

.draggable {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
  margin: 0px;
  padding: 0px;
}

.board_title {
  font-size: 1.2em;
  color: #48465b;
  margin-top: 5px;
  margin-bottom: 15px;
  font-weight: 500;
  text-align: center;
}

.calculation_search {
  border-radius: 10px;
  border: none;
  margin-bottom: 15px;
}

.kt-portlet {
  border-radius: 10px !important;
}

.rawmaterial_info {
  display: inline;
  font-size: 1rem;
  font-weight: 300;

  transition: color 0.3s ease;
  color: #595d6e;
}

.kt-wizard-v2__aside {
  flex: none !important;
  width: 300px !important;
}

.pulsate {
  animation: pulse 0.8s alternate infinite;
  -webkit-animation: pulse 0.8s alternate infinite;
}
@keyframes pulse {
  from {
    color: "";
  }
  to {
    color: #5d78ff;
  }
}
@-webkit-keyframes pulse {
  from {
    color: "";
  }
  to {
    color: #5d78ff;
  }
}

.locked {
  pointer-events: none;
  opacity: 0.65;
}

.pulsatebutton {
  animation: pulsebtn 0.8s alternate infinite;
  -webkit-animation: pulsebtn 0.8s alternate infinite;
}
@keyframes pulsebtn {
  from {
    background-color: #ff7e7e;
  }
  to {
    background-color: #ff0000;
  }
}
@-webkit-keyframes pulsebtn {
  from {
    background-color: #ff7e7e;
  }
  to {
    background-color: #ff0000;
  }
}

.fc-timeline-event {
  padding: 7px 7px 7px 20px !important;
  margin: 3px 3px 3px 3px;
  background: #fff !important;
  border: 1px solid #ebedf2 !important;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055) !important;
  border-radius: 4px;
  cursor: grab !important;
}

.fc-cell-text {
  color: #74788d;
  font-size: 1rem;
  font-weight: 500;
  cursor: grab !important;
}

.calendar-dot-green {
  display: inline-block;
  content: " ";

  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: #0abb87;
  margin-right: 3px;
  margin-left: -13px;
}
.calendar-dot-red {
  display: inline-block;
  content: " ";

  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: #fd395a;
  margin-right: 3px;
  margin-left: -13px;
}

.opacity04 {
  opacity: 0.4 !important;
}

button.btn.btn-disabled:hover {
  background-color: rgba(0, 140, 255, 0.1) !important;
  color: rgba(0, 140, 255, 1) !important;
  cursor: not-allowed !important;
}

.calendar-dot-orange {
  display: inline-block;
  content: " ";

  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: #ffb822;
  margin-right: 3px;
  margin-left: -13px;
}

.calendar-dot-grey {
  display: inline-block;
  content: " ";

  height: 10px;
  width: 10px;
  border-radius: 50%;

  background: #dddddd;
  margin-right: 3px;
  margin-left: -13px;
}

.fc-event {
  color: #48465b !important;
  background-color: white;
  cursor: grab !important;
}

.pending-order {
  padding: 7px 7px 7px 20px !important;
  margin: 3px 3px 3px 3px;
  background: #fff !important;
  border: 1px solid #ebedf2 !important;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.055) !important;
  border-radius: 4px;
  cursor: grab !important;
}

.fc-event:hover {
  background-color: #fafafa !important;
  cursor: grab !important;
}

.react-datepicker-wrapper {
  background-color: white;

  border: 1px solid #e2e5ec;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  width: 100px;

  padding: 0.1rem 0.3rem;

  font-weight: 400;

  color: #495057;
}

.react-datepicker-wrapper input {
  border: none;
  width: 100%;
}

.dz-hover:hover {
  background-color: #fafafa !important;
  transition: all 0.3s;
}

button.disabled {
  cursor: not-allowed !important;
}
.disabled2 {
  pointer-events: none; //This makes it not clickable
  opacity: 0.5; //This grays it out to look disabled
}

.cursorhand {
  cursor: pointer;
}

.fgb0 {
  margin-bottom: 0.3rem;
}

.distribute_even {
  display: flex;
  justify-content: space-between;
}

.lightblue {
  color: "#39b5fd" !important;
  background-color: "#39b5fd" !important;
}

.doughnuttest {
  position: absolute;
  top: 27%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: 500;
  color: #181818;
}

.container-box {
  width: 100%;
  height: 100%;
}

.doughnut {
  width: 50%;
  display: inline-block;
}

.lightblue {
  color: rgb(57, 181, 253);
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.invoicerow {
  opacity: 0.3;
  transition: all 1s;
}
.invoicerow:hover {
  opacity: 0.8;
}

.table-default {
  transition: all 0.3s;
}

.table-hover:hover {
  cursor: pointer;
  background-color: $light !important;
  transition: all 0.3s;
}

.table-selected {
  transition: all 0.3s;
  background-color: rgb(187, 255, 185);
}

.table-selected-hover:hover {
  cursor: pointer;
  background-color: rgb(179, 248, 179);
}

.customdatepicker {
  border: none !important;
  font-size: 0.9rem;
  color: #74788d;
  font-weight: 400;
  line-height: 1rem;
}

.dangerborder {
  border: 2px solid #ff0000 !important;
}

.zentriert {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.custombtn {
  background-color: #fafafa;
  border: none;
  transition: all 0.2s;
}

.custombtn:hover {
  color: white;
  background-color: #0abb87;
}

.nopadding td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.paybutton {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.due {
  background-color: #ffa79b !important;
}
.open {
  background-color: #ffbeb5 !important;
}

.paid {
  background-color: #cefdd8 !important;
}

.partlyPaid {
  background-color: #f1fdf6 !important;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 0.4rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.Toastify__toast--success {
  background: "#0abb87" !important;
  background-color: "#0abb87" !important;
}

.overlay {
  position: fixed; /* Sit on top of the page content */

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.week {
  background-color: #fafafa;
  border: none;
  transition: all 0.2s;
}

.week:hover {
  color: white;
  background-color: #e7e7e7;
}

.weekOpacity {
  opacity: 0.15;
  transition: all 0.2s;
}
.weekOpacity:hover {
  opacity: 1;
}

.pos-deleteImg {
  position: absolute;
  left: 190px;
  top: -10px;
  background: red !important;
  color: white !important;
  transition: all 0.2s;
  height: 30px !important;
  width: 30px !important;
}

.pos-deleteImg:hover {
  cursor: pointer;
  left: 185px;
  top: -15px;
  height: 40px !important;
  width: 40px !important;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &:focus-within {
    border: 1px solid #0052cc;
  }
  input {
    flex: 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 10px;
  list-style: none;
  border-radius: 10px;
  font-weight: 800;
  margin: 0 4px 4px 0;
  background: #0abb87;
}
.tag-title {
  margin-top: 0px;
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: white;
  border-radius: 50%;
  /*  background: #fff; */
  cursor: pointer;
}

.recipeList li {
  list-style: none;
  background: #fafafa !important;
  padding: 5px;
}

.recipeList li:nth-child(odd) {
  list-style: none;
  background: #eeeeee !important;
}

.recipeList {
  padding-left: 0 !important;
}

.btn-label-light {
  background: #f5f5f5 !important; // ganz wenig dunkel

  color: #4b4b4b !important;
}

.oldOffer {
  opacity: 0.25;
  transition: all 0.2s;
}
.oldOffer:hover {
  opacity: 1;
}

.tooltip-inner {
  max-width: none !important;
}

.custom-icon {
  font-size: 2.7rem;
  color: #a2a5b9;
  font-weight: 400;
}

.calculation-margin-text {
  color: #48465b;
  font-weight: 600;
  font-size: 1.2rem;
}

.calculation-info-text {
  color: #595d6e;
  font-weight: 600;
  font-size: 0.95rem;
}

.accordion tr.collapsed .svg-icon-rotate svg,
.accordion .kt-portlet div.collapsed .svg-icon-rotate svg {
  g [fill] {
    transition: fill .15s ease, opacity .15s ease;
  }
  transition: all .15s ease;
  transform: rotate(0);
}

.accordion tr:not(.collapsed) .svg-icon-rotate svg,
.accordion .kt-portlet div:not(.collapsed) .svg-icon-rotate svg {
  g [fill] {
    fill: $primary !important;
    opacity: 0.9 !important;
    transition: fill .15s ease, opacity .15s ease;
  }
  transition: all .15s ease;
  transform: rotate(90deg);
}

.accordionToggle:hover {
  background-color: #f2f2f2;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}