.fc-unthemed .fc-day-grid td:not(.fc-axis) {
  padding: 2px !important;
}

.fc-dayGrid-view th.fc-week-number {
  vertical-align: middle
}

.fc-unthemed .fc-popover .fc-body .fc-event-container {
  overflow-y: auto;
  max-height: 50vh;
}

.fc-more-popover {
  width: auto !important;
  max-width: 350px !important;
}

.fc-unthemed .fc-event, .fc-unthemed .fc-event-dot {
  box-shadow: none !important;
  border: none !important;
}