//
// Widget13
//

.kt-widget13 {
  /* padding: 2rem 0; */

  .kt-widget13__item {
    display: flex;
    align-items: center;
    /* margin-bottom: 2rem; */

    .kt-widget13__desc {
      color: kt-base-color(label, 2);
      /*   text-align: right; */
      padding-right: 1rem;
      font-weight: 400;
    }

    .kt-widget13__text {
      color: kt-base-color(label, 2);
      padding-left: 1rem;
      font-weight: 400;

      &.kt-widget13__text--bold {
        color: kt-base-color(label, 3);
        font-size: 1rem;
        font-weight: 500;
      }
    }

    > span {
      flex: 1;
    }

    &:lasst-child {
      margin-bottom: 0;
    }
  }

  .previewImg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-green {
    color: #0abb87 !important;
  }

  .kt-widget13__action {
    margin-top: 30px;
    padding-top: 30px;

    .kt-widget__detalis {
      margin-right: 10px;
      border-top: 0.07rem dashed kt-base-color(grey, 2);
    }

    .kt-widget13__action {
      border-top: 0.07rem dashed kt-base-color(grey, 2);
    }
  }
}
